import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Layout, Typography, Col, Row, Space, Avatar, Grid, Dropdown } from 'antd';
import UserAvatar from '../../assets/avatar.png';
import WhiteLogo from '../../assets/logo-white.png';
import BlueLogo from '../../assets/logo.png';
import moment from 'moment';
import { UserProviderContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';

const MenuItems = [
    {
        key: '1',
        label: 'Sign Out'
    }
];

const MainHeader = ({ textPrimary, hideWelcome, primaryLogo, primaryBg }) => {
    const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

    const { Header } = Layout;
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const appSignOut = () => {
        Auth.signOut().then(() => {
            window.location.reload();
        });
    };

    const { userState } = useContext(UserProviderContext);
    const { name, given_name } = userState?.cognitoUser;

    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm A'));
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Header
            className={`
            ${hideWelcome ? 'h-[180px]' : 'h-[300px]'}
            ${primaryBg ? 'bg-primary' : 'bg-transparent'}
            ${xs ? 'px-9 py-8' : 'py-10'}
            z-10 mx-auto w-full max-w-[1920px]`}>
            <Row className="h-full flex-col justify-between">
                <Row justify="space-between">
                    <Col>
                        <Space align="center">
                            <img
                                className={`${xs ? 'h-[50px]' : 'h-[80px]'} cursor-pointer`}
                                src={primaryLogo ? BlueLogo : WhiteLogo}
                                alt="logo"
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                            <Text className={`font-bold ${textPrimary ? 'text-primary' : 'text-white'}`}>
                                {currentTime}
                            </Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical" size={120}>
                            <Dropdown menu={{ items: MenuItems, onClick: appSignOut }}>
                                <Space align="end" className="cursor-pointer">
                                    <Text
                                        className={`
                                        ${textPrimary ? 'text-primary' : 'text-white'}
                                        ${xs ? 'hidden' : ''}
                                        font-bold`}>
                                        {name}
                                    </Text>
                                    <Avatar src={UserAvatar} className="h-[50px] w-auto" />
                                </Space>
                            </Dropdown>
                        </Space>
                    </Col>
                </Row>
                {!hideWelcome && (
                    <Row>
                        <Text className="text-3xl font-semibold text-white">{`Welcome back ${given_name}!`}</Text>
                    </Row>
                )}
            </Row>
        </Header>
    );
};

export default MainHeader;
