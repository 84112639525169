import SolArch from '../assets/sa.png';
import Agile from '../assets/agile.png';
import FS from '../assets/fullstack.png';
import DevOps from '../assets/devops.png';
import SRE from '../assets/sre.png';

export const pathwayData = [
    {
        image: SolArch,
        description: 'Solution Architect (Pre-Assessed)',
        disabled: false,
        slug: 'solutions-architect',
        title: 'Solutions Architect'
    },
    {
        image: DevOps,
        description: 'DevOps Manager',
        disabled: true,
        slug: 'devops-manager',
        title: 'DevOps Manager'
    },
    {
        image: FS,
        description: 'Full Stack Developer',
        disabled: true,
        slug: 'full-stack-developer',
        title: 'Full Stack Developer'
    },
    {
        image: Agile,
        description: 'Agile Coach',
        disabled: true,
        slug: 'agile-coach',
        title: 'Agile Coach'
    },
    {
        image: SRE,
        description: 'SRE (Site Reliability Engineer)',
        disabled: true,
        slug: 'site-reliability-engineer',
        title: 'Site Reliability Engineer'
    }
];
