import { useState, useEffect } from 'react';
import { Typography, Row, Grid, Card } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Flag from '../../assets/flag.png';
import FlagWhite from '../../assets/flag-white.png';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/home.css';
import { LockOutlined } from '@ant-design/icons';
import EmptyListIndicator from './EmptyListIndicator';

const CarouselSwiper = ({ slides, bgPrimary, emptyMessage }) => {
    const [slidesToShow, setSlidesToShow] = useState(4);
    const [inProgressCourses, setInProgressCourses] = useState([]);
    const [showNavArrows, setShowNavArrows] = useState(true);
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
    const navigate = useNavigate();

    useEffect(() => {
        if (xxl) {
            setSlidesToShow(5);
        } else if (xl) {
            setSlidesToShow(4);
        } else if (lg) {
            setSlidesToShow(4);
        } else if (md) {
            setSlidesToShow(3);
        } else if (sm) {
            setSlidesToShow(2);
        } else {
            setSlidesToShow(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xs, sm, md, lg, xl, xxl]);

    useEffect(() => {
        // setInProgressCourses(() => {
        //     return slides
        //         .filter((item) => {
        //             return isPercentInProg(item.percent)
        //         });
        setInProgressCourses(slides);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides]);

    useEffect(() => {
        if (slides.length <= slidesToShow) {
            setShowNavArrows(false);
        } else {
            setShowNavArrows(true);
        }
    }, [slides, slidesToShow]);

    return (
        <>
            {slides.length === 0 ? (
                <EmptyListIndicator message={emptyMessage} />
            ) : (
                <Swiper
                    slidesPerView={slidesToShow}
                    spaceBetween={16}
                    pagination={{
                        clickable: true
                    }}
                    // loop={true}
                    navigation={{
                        nextEl: '.image-swiper-button-next',
                        prevEl: '.image-swiper-button-prev'
                    }}
                    style={{ width: '100%' }}
                    className={`swiper-main`}
                    modules={[Pagination, Navigation]}>
                    {inProgressCourses?.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <Row
                                    onClick={() => {
                                        if (item.disabled) {
                                            return null;
                                        } else {
                                            navigate(`/course/${item.id}`);
                                        }
                                    }}
                                    style={{
                                        borderRadius: 0,
                                        border: 0,
                                        position: 'relative',
                                        width: '100%'
                                    }}>
                                    <Card
                                        style={{
                                            borderRadius: 0,
                                            border: 0,
                                            width: '100%',
                                            backgroundColor: 'transparent',
                                            cursor: item.disabled ? 'not-allowed' : 'pointer'
                                        }}
                                        bodyStyle={{ padding: 0 }}>
                                        <div
                                            style={{
                                                backgroundColor: 'black',
                                                position: 'relative',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <img
                                                alt={item.title}
                                                src={item.coverPhotoUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    filter: item.disabled ? 'grayscale(100%)' : 'none',
                                                    opacity: item.disabled ? 0.15 : 1,
                                                    pointerEvents: item.disabled ? 'none' : 'auto'
                                                }}
                                            />
                                            {item.disabled && (
                                                <LockOutlined
                                                    style={{
                                                        position: 'absolute',
                                                        color: 'white',
                                                        fontSize: '60px'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <Row
                                            style={{
                                                padding: '10px',
                                                height: lg ? '120px' : '140px',
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingRight: '24px'
                                            }}
                                            className={`${bgPrimary ? 'bg-primary' : 'bg-white'}`}>
                                            <Text style={{ fontWeight: '600', color: bgPrimary ? 'white' : '' }}>
                                                {item.title}
                                            </Text>
                                            <Row style={{ marginTop: '24px', justifyContent: 'space-between' }}>
                                                <Text style={{ color: bgPrimary ? 'white' : '' }}>
                                                    {item.courseCode}
                                                </Text>
                                                <Row>
                                                    <img
                                                        alt="example"
                                                        src={bgPrimary ? FlagWhite : Flag}
                                                        style={{ marginRight: '4px' }}
                                                    />
                                                    <Text
                                                        style={{ fontWeight: 'bold', color: bgPrimary ? 'white' : '' }}>
                                                        {parseFloat(item.percent)}%
                                                    </Text>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Card>
                                </Row>
                            </SwiperSlide>
                        );
                    })}

                    <div
                        className="swiper-button image-swiper-button-next"
                        style={{ display: showNavArrows ? 'flex' : 'none' }}>
                        <RightOutlined />
                    </div>

                    <div
                        className="swiper-button image-swiper-button-prev"
                        style={{ display: showNavArrows ? 'flex' : 'none' }}>
                        <LeftOutlined />
                    </div>
                </Swiper>
            )}
        </>
    );
};

export default CarouselSwiper;
