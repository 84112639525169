import React from 'react';
import { Button } from 'antd';
import { Auth } from 'aws-amplify';

function appSignOut() {
    Auth.signOut().then(() => {
        window.location.reload();
    });
}

const signOutBtnSyle = {
    position: 'fixed',
    top: 0,
    right: 20
};

const SignOutButton = ({ type, ...restProps }) => {
    return (
        <Button type={type} onClick={() => appSignOut()} {...restProps} style={signOutBtnSyle} className="text-primary">
            Sign out
        </Button>
    );
};

export default SignOutButton;
