import React from 'react';
import OverviewTab from './tabs/OverviewTab';
import LabResponseTab from './tabs/LabResponseTab';
import DownloadExerciseFiles from './tabs/DownloadExerciseFiles';
import { Row, Tabs, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CarouselSwiper from '../../layout/CarouselSwiper';

const CourseTabs = ({
    sessionType,
    currentLesson,
    holdTextAreaValue,
    hasTimerExpired,
    isSubmitting,
    handleLabResponseSubmit,
    exerciseDetails,
    courses,
    assignmentInfo
}) => {
    const { Title } = Typography;
    const navigate = useNavigate();

    const pathwayTitle = localStorage.getItem('pathway-title');
    const pathwaySlug = localStorage.getItem('pathway-slug');

    const tabsItems = [
        {
            key: '1',
            label: `Overview`,
            children: <OverviewTab currentLesson={currentLesson} />
        },
        {
            key: '2',
            label: `${sessionType === 'Assignment' ? 'Assignment' : 'Lab'} Response`,
            children: (
                <LabResponseTab
                    currentLesson={currentLesson}
                    holdTextAreaValue={holdTextAreaValue}
                    hasTimerExpired={hasTimerExpired}
                    isSubmitting={isSubmitting}
                    handleLabResponseSubmit={handleLabResponseSubmit}
                    assignmentInfo={assignmentInfo}
                    sessionType={sessionType}
                />
            )
        },
        {
            key: '3',
            label: `Exercise Files`,
            children: <DownloadExerciseFiles currentLesson={currentLesson} exerciseDetails={exerciseDetails} />
        }
    ];
    return (
        <Row
            style={{
                height: '60px',
                width: '100%',
                justifyContent: 'center',
                marginTop: '60px'
            }}
            className="bg-primary">
            {sessionType === 'Lab' && (
                <Tabs
                    defaultActiveKey="1"
                    className="home-tabs"
                    items={tabsItems}
                    centered
                    style={{
                        width: '100vw',
                        backgroundColor: 'transparent'
                    }}></Tabs>
            )}
            {sessionType === 'Assignment' && (
                <Tabs
                    defaultActiveKey="1"
                    className="home-tabs"
                    items={tabsItems.filter((item) => item.label === 'Assignment Response')}
                    centered
                    style={{
                        width: '100vw',
                        backgroundColor: 'transparent'
                    }}></Tabs>
            )}
            {/* {sessionType !== 'Quiz' && (
                <Row
                    style={{
                        width: '100%',
                        paddingBottom: '60px'
                    }}>
                    <Row
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: sessionType === 'Lab' ? '0px' : '80px'
                        }}>
                        <Title
                            level={5}
                            style={{
                                fontWeight: '600',
                                color: primaryColor,
                                alignSelf: 'flex-start'
                            }}>
                            Other Courses
                        </Title>
                        <ArrowRightOutlined
                            style={{ color: primaryColor }}
                            onClick={() => {
                                navigate(`/pathway/${pathwaySlug}?t=${pathwayTitle}`);
                            }}
                        />
                    </Row>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <CarouselSwiper slides={courses} bgPrimary={true} />
                    </div>
                </Row>
            )} */}
        </Row>
    );
};
export default CourseTabs;
