import { Typography } from 'antd';
import Logo from '../../../assets/logo.png';
const { Text } = Typography;

const CourseLoader = ({ isLesson = true }) => {
    return (
        <div className="flex h-[80vh] w-full flex-col items-center justify-center">
            <img src={Logo} alt={'logo'} className="h-[100px]" />
            <Text className="mt-3 text-lg">{isLesson ? 'Loading lesson...' : 'Loading...'}</Text>
        </div>
    );
};

export default CourseLoader;
