import React, { useEffect, useRef, useState } from 'react';
import { Layout, Grid } from 'antd';
import {
    UnlockOutlined,
    VideoCameraOutlined,
    FileMarkdownOutlined,
    LinkOutlined,
    OrderedListOutlined,
    CarryOutOutlined
} from '@ant-design/icons';
import '../../assets/css/course.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    getCoursesByUserPathways,
    getPathwaysByOrg,
    getCurrentLessonByUser,
    completeUserLesson,
    submitLabResponse,
    getAssignment,
    submitAssignmentResponse,
    completeAssignment
} from '../../data/shared';
import { GetAssignmentAPI, GetCourseModules, GetLesson } from '../../vendor/SpringboardAPI';
import PageLoader from '../layout/loaders/PageLoader';
import VideoContent from './contentTypes/VideoContent';
import MarkdownContent from './contentTypes/MarkdownContent';
import UrlContent from './contentTypes/UrlContent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CourseLoader from '../layout/loaders/CourseLoader';
import QuizContent from './contentTypes/QuizContent';
import AssignmentContent from './contentTypes/AssignmentContent';
import Breadcrumbs from './courseComponents/Breadcrumbs';
import { showSuccessToast } from '../../utils/ToastSuccess';
import { showErrorToast } from '../../utils/ToastError';
import MobileMenu from './courseComponents/sidebar/MobileMenu';
import DesktopMenu from './courseComponents/sidebar/DesktopMenu';
import LabResponseModal from './courseComponents/LabResponseModal';
import CourseTabs from './courseComponents/CourseTabs';
import DesktopCompleteButtonAndTimer from './courseComponents/DesktopCompleteButtonAndTimer';
import MobileCompleteButtonAndTimer from './courseComponents/MobileCompleteButtonAndTimer';
import { isEnabled } from '../../utils/isEnabled';

const CoursePage = () => {
    const [modules, setModules] = useState([]);
    const [courses, setCourses] = useState([]);
    const [lessonStartTime, setLessonStartTime] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [loadingLesson, setLoadingLesson] = useState(false);
    const [currentLessonIndicator, setCurrentLessonIndicator] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [exerciseDetails, setExerciseDetails] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [completeLessonModalOpen, setcompleteLessonModalOpen] = useState(false);
    const [completeLessonIsLoading, setCompleteLessonIsLoading] = useState(false);
    const [submitLabResponsePromptModalOpen, setSubmitLabResponsePromptModalOpen] = useState(false);
    const [holdTextAreaValue, setHoldTextAreaValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasTimerExpired, setHasTimerExpired] = useState(false);
    const [forceSubmitPromptModalOpen, setForceSubmitPromptModalOpen] = useState(false);
    const [sessionType, setSessionType] = useState(null);

    const [assignmentInfo, setAssignmentInfo] = useState(null);
    const [assignmentContent, setAssignmentContent] = useState(null);

    const scrollIntoViewRef = useRef(null);
    const { Content } = Layout;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const { slug, contentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultLabResponse = `{}`;

    const getAssignmentInput = {
        assignmentId: contentId,
        courseId: slug
    };

    useEffect(() => {
        const fetchLesson = async () => {
            const getCourseModules = await GetCourseModules(slug);
            setModules(getCourseModules.cycle);

            const { lab: lesson } = await GetLesson(contentId);

            // GET USER LESSONS DATA on TLP BACKEND HERE BEFORE SETTING LESSON
            const fetchResponse = await getCurrentLessonByUser(slug);

            let {
                status = null,
                substatus = null,
                submissions = null,
                timeStarted = null,
                insert = false
            } = fetchResponse || {};

            if (!fetchResponse) {
                status = 'NOT_STARTED';
                substatus = 'IS_NOT_CURRENT';
                insert = true;
            }

            setCurrentLesson({
                ...lesson,
                status,
                substatus,
                submissions,
                timeStarted,
                insert,
                courseId: slug
            });

            setLessonStartTime(timeStarted);

            let currentModule = getCourseModules.cycle.sessions.find((item) =>
                item.sessionItems.some((child) => child.id === contentId)
            );
            let currentLessonIndex = currentModule.sessionItems.findIndex((element) => element.id === contentId);
            // console.log('Current Module', currentModule);

            let initialSessionType = currentModule.sessionItems[currentLessonIndex].__typename;
            setSessionType(initialSessionType);

            // let lessonTitle = currentModule.sessionItems[currentLessonIndex].title;

            setCurrentLessonIndicator({
                ...currentLessonIndicator,
                module: currentModule.title,
                lesson: `Lesson ${currentLessonIndex + 1}`,
                lessonTitle: currentModule.sessionItems[currentLessonIndex].title
            });

            if (lesson?.exerFileContent) {
                let tempExerciseDetails = JSON.parse(lesson?.exerFileContent);
                setExerciseDetails({
                    title: tempExerciseDetails.title,
                    link: tempExerciseDetails.link
                });
            }
        };

        fetchLesson();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let currentModule = menuItems?.find((item) => item.children.some((child) => child.key === contentId));
        let currentLessonIndex = currentModule?.children?.findIndex((element) => element.key === contentId);
        let currentSessionType = currentModule?.children[currentLessonIndex].__typename;

        setSessionType(currentSessionType);

        const fetchLesson = async () => {
            setLoadingLesson(true);
            const { lab: lesson } = await GetLesson(contentId);
            // GET USER LESSONS DATA FOR "SPECIFIC LESSON" on TLP BACKEND HERE BEFORE SETTING LESSON
            const fetchResponse = await getCurrentLessonByUser(slug, lesson.id);

            let {
                status = null,
                substatus = null,
                submissions = null,
                timeStarted = null,
                insert = false
            } = fetchResponse || {};

            if (!fetchResponse) {
                status = 'NOT_STARTED';
                substatus = 'IS_NOT_CURRENT';
                insert = true;
            }

            if (lesson.exerFileContent) {
                let tempExerciseDetails = JSON.parse(lesson.exerFileContent);
                setExerciseDetails({
                    title: tempExerciseDetails.title,
                    link: tempExerciseDetails.link
                });
            }

            setCurrentLesson({
                ...lesson,
                status,
                substatus,
                submissions,
                timeStarted,
                insert,
                courseId: slug
            });

            setLessonStartTime(timeStarted);
            setLoadingLesson(false);
        };

        const fetchAssignment = async () => {
            setLoadingLesson(true);

            const _assignmentContent = await GetAssignmentAPI(contentId);
            setAssignmentContent(_assignmentContent.assignment);

            const _assignmentInfo = await getAssignment(getAssignmentInput);
            setAssignmentInfo(_assignmentInfo);
            // setCurrentLesson(null);
            setLoadingLesson(false);
        };

        // Checks if currentLesson already exist on
        // the first useEffect to avoid race condition
        if (currentLesson) {
            if (currentSessionType === 'Lab') {
                fetchLesson();
            }
            if (currentSessionType === 'Assignment') {
                fetchAssignment();
            }
        }
        setCurrentLessonIndicator({
            ...currentLessonIndicator,
            module: currentModule?.label,
            lesson: `Lesson ${currentLessonIndex + 1}`,
            lessonTitle: currentModule?.children[currentLessonIndex]?.label
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, sessionType]);

    useEffect(() => {
        if (modules?.sessions) {
            const tempArray = modules.sessions.flatMap((session, i) => {
                let lessonIndex = 0;

                session?.sessionItems.sort((a, b) => {
                    let labelA = a.title.toLowerCase().split(':')[0];
                    let labelB = b.title.toLowerCase().split(':')[0];

                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                });

                const children = session?.sessionItems.map((item) => {
                    const icon = item.__typename === 'Lab' ? item.contentType : item.__typename;
                    lessonIndex++;
                    return {
                        ...getItem(item.title, item.id, getContentTypeIcon(icon)),
                        lessonindex: lessonIndex,
                        lessontype: item.contentType || 'Markdown', // Handle the case when contentType is not available
                        __typename: item.__typename
                    };
                });

                return getItem(session.title, session.id, <UnlockOutlined />, children);
            });

            setMenuItems(tempArray);
            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modules]);

    useEffect(() => {
        const fetchOtherCourses = async () => {
            let _pathways = await getPathwaysByOrg();
            let activePathway = _pathways.find((pw) => pw.status === 'ACTIVATED');

            let _courses = await getCoursesByUserPathways(activePathway.id);

            setCourses(
                _courses.map((crs) => {
                    return {
                        ...crs,
                        disabled: !isEnabled(parseFloat(crs.percent), crs.deps)
                    };
                })
            );
            checkTimerExpiresOnInit();
        };

        if (currentLesson && sessionType === 'Lab') {
            try {
                //temporarily commenting this out
                // fetchOtherCourses();
            } catch (error) {
                console.log(`Error fetching other courses on course content.`);
                throw Error(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLesson]);

    function getContentTypeIcon(item) {
        switch (item) {
            case 'Markdown':
                return <FileMarkdownOutlined />;
            case 'Video':
                return <VideoCameraOutlined />;
            case 'URL':
                return <LinkOutlined />;
            case 'Quiz':
                return <OrderedListOutlined />;
            case 'Assignment':
                return <CarryOutOutlined />;
            default:
                return <FileMarkdownOutlined />;
        }
    }
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type
        };
    }

    const handleLabResponseSubmit = async ({ textareaValue, timerExpired }) => {
        const submitLabResponseData = {
            courseId: currentLesson.courseId,
            lessonId: currentLesson.id,
            submissions: textareaValue
        };

        const submitAssignmentResponseData = {
            courseId: slug,
            assignmentId: contentId,
            submissions: textareaValue
        };

        setIsSubmitting(true);

        let submitResponseResult;

        if (sessionType === 'Lab') {
            submitResponseResult = await submitLabResponse(submitLabResponseData);
        }

        if (sessionType === 'Assignment') {
            submitResponseResult = await submitAssignmentResponse(submitAssignmentResponseData);
            setAssignmentInfo(submitResponseResult);
        }
        // const submitResponseResult = await submitLabResponse(submitLabResponseData);

        if (!timerExpired) {
            setCurrentLesson({
                ...currentLesson,
                submissions: textareaValue
            });
        }

        setIsSubmitting(false);

        if (textareaValue !== '{}') {
            if (submitResponseResult) {
                showSuccessToast({
                    message: `${sessionType === 'Assignment' ? 'Assignment' : 'Lab'} Response Submitted`
                });
            } else {
                showErrorToast({
                    message: `${sessionType === 'Assignment' ? 'Assignment' : 'Lab'} Response Submission Failed`
                });
            }
        }
    };

    const handleTimerExpires = async () => {
        setCompleteLessonIsLoading(true);
        setHasTimerExpired(true);

        //explicitly declare timerExpired since setting setHasTimerExpired has a delay and
        //thus it is not immediately recognized as true when handleLabResponseSubmit function below is called
        await handleLabResponseSubmit({ textareaValue: defaultLabResponse, timerExpired: true });
        await handleComplete();

        setCompleteLessonIsLoading(false);
        setForceSubmitPromptModalOpen(false);
        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const checkTimerExpiresOnInit = async () => {
        const timerRemaining = computeDeadline();
        if (
            currentLesson?.contentType === 'Markdown' &&
            timerRemaining <= 0 &&
            currentLesson.status === 'IN_PROGRESS'
        ) {
            setForceSubmitPromptModalOpen(true);
        }
    };

    const handleCloseSubmitLabResponseModal = () => {
        let element = document.querySelector('.scroll-into-lab-response');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        } else {
            return null;
        }
        setSubmitLabResponsePromptModalOpen(false);
    };

    const handleComplete = async () => {
        const completeLesson = async () => {
            setCompleteLessonIsLoading(true);

            const params = { courseId: slug, lessonId: contentId };

            await completeUserLesson(params);

            setLessonStartTime(null);

            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
            setHoldTextAreaValue('');
            setCurrentLesson((prev) => ({ ...prev, status: 'COMPLETED' }));
            setCompleteLessonIsLoading(false);
        };

        const completeUserAssignment = async () => {
            setCompleteLessonIsLoading(true);

            const params = { courseId: slug, assignmentId: contentId };

            const _assignmentInfo = await completeAssignment(params);
            setAssignmentInfo(_assignmentInfo);
            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
            setCompleteLessonIsLoading(false);
        };

        if (sessionType === 'Lab') {
            completeLesson();
        }

        if (sessionType === 'Assignment') {
            completeUserAssignment();
        }
    };

    const onCompleteLessonClick = async () => {
        if (sessionType === 'Assignment') {
            if (!assignmentInfo?.submissions) {
                setSubmitLabResponsePromptModalOpen(true);
                return;
            } else {
                setcompleteLessonModalOpen(true);
                return;
            }
        }

        if (currentLesson.contentType === 'Markdown' || currentLesson.contentType === null) {
            if (currentLesson.submissions) {
                setcompleteLessonModalOpen(true);
            } else {
                let textAreaContent = document.querySelector('.labResponseTextArea');
                setHoldTextAreaValue(textAreaContent?.innerHTML);
                setSubmitLabResponsePromptModalOpen(true);
            }
        }

        if (currentLesson.contentType !== null && currentLesson.contentType !== 'Markdown') {
            setCompleteLessonIsLoading(true);
            if (currentLesson.submissions === null) {
                await handleLabResponseSubmit({ textareaValue: defaultLabResponse });
            }
            await handleComplete();
        }
    };

    const computeDeadline = () => {
        let startTime = lessonStartTime || Date.now();
        let quizDuration = currentLesson?.durationMins * 60000;

        const nowDiff = Date.now() - startTime;
        const remaining = quizDuration - nowDiff;

        if (remaining <= 0) {
            return 0;
        }

        return Date.now() + remaining;
    };

    // TIMER AND COMPLETE BUTTON DISPLAY CONDITIONS
    const showRunningTime =
        currentLesson?.status !== 'COMPLETED' &&
        lessonStartTime &&
        !hasTimerExpired &&
        (currentLesson?.contentType === 'Markdown' || currentLesson?.contentType === null);

    const showExpiredTime =
        (currentLesson?.contentType === 'Markdown' &&
            currentLesson?.status === 'COMPLETED' &&
            currentLesson?.submissions === '{}') ||
        hasTimerExpired;

    const showCompleteLessonButton = currentLesson?.status === 'IN_PROGRESS' && lessonStartTime;

    const showLessonCompletedButton = currentLesson?.status === 'COMPLETED';

    return (
        <>
            {modules.length === 0 ? (
                <PageLoader />
            ) : (
                <Layout className="relative overflow-x-hidden bg-white">
                    {/* MOBILE MENU */}
                    {isMenuOpen && (
                        <MobileMenu
                            modules={modules}
                            menuItems={menuItems}
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            contentId={contentId}
                        />
                    )}
                    <Layout className="course-layout">
                        {/* DESKTOP MENU */}
                        <DesktopMenu
                            modules={modules}
                            menuItems={menuItems}
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            contentId={contentId}
                            xs={xs}
                        />
                        {loadingLesson ? (
                            <CourseLoader />
                        ) : (
                            // COURSE CONTENT
                            <Layout className="course-content">
                                <div ref={scrollIntoViewRef}></div>

                                <div
                                    className={`${
                                        xs ? 'flex-col' : 'flex-row'
                                    } sticky top-0 z-10 flex items-center justify-between bg-white px-6 py-3`}>
                                    <div className={`${xs ? 'w-full' : ''} flex h-[40px] items-center justify-between`}>
                                        {/* BREADCRUMBS */}
                                        <Breadcrumbs currentLessonIndicator={currentLessonIndicator} />

                                        {/* MOBILE TIMER AND COMPLETE BUTTON */}
                                        {xs && (
                                            <MobileCompleteButtonAndTimer
                                                currentLesson={currentLesson}
                                                completeLessonModalOpen={completeLessonModalOpen}
                                                onCompleteLessonClick={onCompleteLessonClick}
                                                setcompleteLessonModalOpen={setcompleteLessonModalOpen}
                                                setIsMenuOpen={setIsMenuOpen}
                                                isMenuOpen={isMenuOpen}
                                                sessionType={sessionType}
                                                assignmentInfo={assignmentInfo}
                                            />
                                        )}
                                    </div>

                                    {/* DESKTOP TIMER AND COMPLETE BUTTON */}
                                    {(sessionType === 'Lab' || sessionType === 'Assignment') && (
                                        <DesktopCompleteButtonAndTimer
                                            showRunningTime={showRunningTime}
                                            showExpiredTime={showExpiredTime}
                                            showCompleteLessonButton={showCompleteLessonButton}
                                            showLessonCompletedButton={showLessonCompletedButton}
                                            computeDeadline={computeDeadline}
                                            setForceSubmitPromptModalOpen={setForceSubmitPromptModalOpen}
                                            completeLessonIsLoading={completeLessonIsLoading}
                                            onCompleteLessonClick={onCompleteLessonClick}
                                            sessionType={sessionType}
                                            assignmentInfo={assignmentInfo}
                                        />
                                    )}
                                </div>

                                <Content className={`${isMenuOpen ? 'no-scroll' : ''} m-0 mt-6 min-h-[280px] px-6`}>
                                    {!sessionType ? (
                                        <CourseLoader />
                                    ) : (
                                        // LESSONS DISPLAY
                                        <>
                                            {sessionType === 'Lab' && (
                                                <>
                                                    {(currentLesson?.contentType === 'Markdown' ||
                                                        currentLesson?.contentType === null) && (
                                                        <MarkdownContent
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                        />
                                                    )}

                                                    {currentLesson?.contentType === 'Video' && (
                                                        <VideoContent
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                        />
                                                    )}

                                                    {currentLesson?.contentType === 'URL' && (
                                                        <UrlContent
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {sessionType === 'Quiz' && <QuizContent courseId={slug} />}
                                            {sessionType === 'Assignment' && (
                                                <AssignmentContent
                                                    assignmentInfo={assignmentInfo}
                                                    assignmentContent={assignmentContent}
                                                    setAssignmentInfo={setAssignmentInfo}
                                                    setAssignmentContent={setAssignmentContent}
                                                />
                                            )}
                                        </>
                                    )}

                                    {/* SUBMIT LAB RESPONSE SCROLL REFERENCE */}
                                    <div
                                        className="scroll-into-lab-response "
                                        style={{ height: '100px', width: '100%' }}></div>

                                    {/* TABS AND OTHER COURSES */}
                                    <CourseTabs
                                        sessionType={sessionType}
                                        currentLesson={currentLesson}
                                        holdTextAreaValue={holdTextAreaValue}
                                        hasTimerExpired={hasTimerExpired}
                                        isSubmitting={isSubmitting}
                                        handleLabResponseSubmit={handleLabResponseSubmit}
                                        exerciseDetails={exerciseDetails}
                                        courses={courses}
                                        assignmentInfo={assignmentInfo}
                                    />
                                </Content>

                                {/* TOAST AND MODALS */}
                                <ToastContainer />
                                <LabResponseModal
                                    completeLessonModalOpen={completeLessonModalOpen}
                                    handleComplete={handleComplete}
                                    setcompleteLessonModalOpen={setcompleteLessonModalOpen}
                                    submitLabResponsePromptModalOpen={submitLabResponsePromptModalOpen}
                                    setSubmitLabResponsePromptModalOpen={setSubmitLabResponsePromptModalOpen}
                                    handleCloseSubmitLabResponseModal={handleCloseSubmitLabResponseModal}
                                    forceSubmitPromptModalOpen={forceSubmitPromptModalOpen}
                                    completeLessonIsLoading={completeLessonIsLoading}
                                    handleTimerExpires={handleTimerExpires}
                                    navigate={navigate}
                                    sessionType={sessionType}
                                />
                            </Layout>
                        )}
                    </Layout>
                </Layout>
            )}
        </>
    );
};

export default CoursePage;
