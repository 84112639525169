import { Button } from 'antd';
import React from 'react';
import { MenuOutlined, CheckOutlined } from '@ant-design/icons';

const MobileCompleteButtonAndTimer = ({
    currentLesson,
    completeLessonModalOpen,
    onCompleteLessonClick,
    setcompleteLessonModalOpen,
    setIsMenuOpen,
    isMenuOpen,
    sessionType,
    assignmentInfo
}) => {
    return (
        <>
            {sessionType === 'Lab' && (
                <div className="flex items-center">
                    {currentLesson?.status === 'IN_PROGRESS' && (
                        <Button
                            className="mr-3 border-solid border-primary bg-primary"
                            size="medium"
                            type="primary"
                            loading={completeLessonModalOpen}
                            onClick={() => {
                                onCompleteLessonClick();
                            }}>
                            <CheckOutlined />
                        </Button>
                    )}
                    {currentLesson?.status === 'COMPLETED' && (
                        <Button
                            className="mr-3"
                            size="medium"
                            disabled={true}
                            type="primary"
                            loading={completeLessonModalOpen}
                            onClick={() => {
                                setcompleteLessonModalOpen(true);
                            }}>
                            <CheckOutlined />
                        </Button>
                    )}
                    <Button
                        size="medium"
                        onClick={() => {
                            setIsMenuOpen(!isMenuOpen);
                        }}
                        className="border-solid border-gray-400 px-2">
                        <MenuOutlined style={{ color: 'gray' }} />
                    </Button>
                </div>
            )}
            {sessionType === 'Assignment' && (
                <div className="flex items-center">
                    {assignmentInfo?.status === 'IN_PROGRESS' && (
                        <Button
                            className="mr-3 border-solid border-primary bg-primary"
                            size="medium"
                            type="primary"
                            loading={completeLessonModalOpen}
                            onClick={() => {
                                onCompleteLessonClick();
                            }}>
                            <CheckOutlined />
                        </Button>
                    )}
                    {assignmentInfo?.status === 'COMPLETED' && (
                        <Button
                            className="mr-3"
                            size="medium"
                            disabled={true}
                            type="primary"
                            loading={completeLessonModalOpen}
                            onClick={() => {
                                setcompleteLessonModalOpen(true);
                            }}>
                            <CheckOutlined />
                        </Button>
                    )}
                    <Button
                        size="medium"
                        onClick={() => {
                            setIsMenuOpen(!isMenuOpen);
                        }}
                        className="border-solid border-gray-400 px-2">
                        <MenuOutlined style={{ color: 'gray' }} />
                    </Button>
                </div>
            )}

            {sessionType === 'Quiz' && (
                <div className="flex items-center">
                    <Button
                        size="medium"
                        onClick={() => {
                            setIsMenuOpen(!isMenuOpen);
                        }}
                        className="border-solid border-gray-400 px-2">
                        <MenuOutlined style={{ color: 'gray' }} />
                    </Button>
                </div>
            )}
        </>
    );
};

export default MobileCompleteButtonAndTimer;
