import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { Hub, Auth, API } from 'aws-amplify';
import { UserProviderContext } from './UserProvider';

const initialState = { isAuthenticated: false, loading: true, isManager: false };
export const AuthProviderContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return { ...state, isAuthenticated: true, loading: false };
        case 'SIGN_IN_AS_MANAGER':
            return { ...state, isAuthenticated: true, loading: false, isManager: true };
        case 'SIGN_OUT':
            return { ...state, isAuthenticated: false, loading: false };
        case 'LOADING':
            return { ...state, loading: true };
        case 'LOADING_FINISH':
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { userDispatch } = useContext(UserProviderContext);

    useEffect(() => {
        if (window.location.pathname.includes('/signin?isManager=true')) {
            dispatch({ type: 'LOADING_FINISH' });
            return;
        }

        const managerToken = localStorage.getItem('PeopleMgrToken');

        if (managerToken) {
            dispatch({ type: 'SIGN_IN_AS_MANAGER' });
        }

        if (!managerToken) {
            const authListener = ({ payload: { event } }) => {
                switch (event) {
                    case 'signIn':
                        dispatch({ type: 'SIGN_IN' });
                        break;
                    case 'signOut':
                        dispatch({ type: 'SIGN_OUT' });
                        break;
                    default:
                        break;
                }
            };

            // Check current user status and dispatch appropriate action
            const checkCurrentAuthStatus = async () => {
                if (!managerToken) {
                    try {
                        let data = await Auth.currentAuthenticatedUser();

                        // set userdata state from cognito
                        userDispatch({
                            type: 'SET_COGNITO_USER',
                            payload: {
                                ...data.attributes,
                                username: data.username
                            }
                        });

                        let USER_NOT_EXISTS = true;
                        const meResult = await API.graphql({
                            query: `
                                query meQuery {
                                    me { id username orgId name email role enrollmentStatus createdAt updatedAt }
                                }
                            `
                        }).catch((e) => USER_NOT_EXISTS);

                        if (meResult === USER_NOT_EXISTS) {
                            userDispatch({ type: 'SET_API_USER', payload: { enrollmentStatus: 'FOR_ASSESSMENT' } });
                        } else {
                            userDispatch({ type: 'SET_API_USER', payload: meResult.data.me });
                        }

                        dispatch({ type: 'SIGN_IN' }); // set isAuthenticated to true
                    } catch (error) {
                        dispatch({ type: 'SIGN_OUT' });
                    }
                }
            };

            // provides the initial authentication status when browser initially loads
            checkCurrentAuthStatus();

            // provides real-time updates about changes in authentication status
            Hub.listen('auth', authListener);

            return () => authListener(); // Clean up listener on unmount
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, []);

    return (
        <AuthProviderContext.Provider value={{ authState: state, authDispatch: dispatch }}>
            {children}
        </AuthProviderContext.Provider>
    );
};
