import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ConsoleLayout from '../components/manager/layout/Layout';
import '../assets/css/managerConsole.css';
import { PeopleManagerProvider } from '../providers/PeopleManagerProvider';
import { useEffect } from 'react';

const ManagerConsole = () => {
    const managerToken = localStorage.getItem('PeopleMgrToken');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!managerToken) {
            if (!location.pathname.includes('/manager-console/register')) {
                navigate('/signin');
            }
        }
    }, []);

    return (
        <PeopleManagerProvider>
            <ConsoleLayout>
                <Outlet></Outlet>
            </ConsoleLayout>
        </PeopleManagerProvider>
    );
};

export default ManagerConsole;
