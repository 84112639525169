import React, { useEffect, useState } from 'react';
import '../../assets/css/home.css';
import '../../assets/css/loader.css';
import { Row, Space } from 'antd';
import { pathwayData } from '../../data/PathwayData';
import PathwayCard from '../pathway/PathwayCard';
import CarouselSwiper from '../layout/CarouselSwiper';
import { getPathwaysByOrg, getCoursesByUserPathways } from '../../data/shared';
import PathwayLoader from '../pathway/PathwayLoader';
import isPercentInProg from '../../utils/isPercentInProg';

const MainContent = () => {
    const [pathways, setPathways] = useState([]);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            let _pathways = await getPathwaysByOrg();
            let activePathway = _pathways.find((pw) => pw.status === 'ACTIVATED');
            let _courses = await getCoursesByUserPathways(activePathway.id);
            setCourses(_courses);
            setPathways(_pathways);
            setIsLoading(false);
        };
        fetch();
    }, []);

    return (
        <Space direction="vertical" className="mt-12 w-full">
            <h2 className="mb-2 text-xl font-semibold text-white">Your Pathway</h2>

            {isLoading ? (
                <PathwayLoader />
            ) : (
                <Row gutter={[16, 16]}>
                    {pathways.map((pw, idx) => {
                        let data = pathwayData.find((obj) => obj.description === pw.title);
                        return (
                            <PathwayCard
                                key={idx}
                                image={data.image}
                                title={pw.title}
                                disabled={pw.status === 'DEACTIVATED'}
                                slug={pw.id}
                            />
                        );
                    })}
                </Row>
            )}

            <h2 className="mb-4 mt-16 text-xl font-semibold text-white">Your Progress</h2>

            {isLoading ? (
                <PathwayLoader />
            ) : (
                <CarouselSwiper
                    slides={courses.filter((item) => {
                        return isPercentInProg(item.percent);
                    })}
                    emptyMessage={'You have no on-going courses.'}
                />
            )}
        </Space>
    );
};

export default MainContent;
