import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { Col, Row, Select, Typography } from 'antd';
import { AiFillBank } from 'react-icons/ai';
import { MdDashboard, MdInsertChart, MdBarChart, MdScience, MdDescription } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

const { Text } = Typography;

const ConsoleSidebar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const menuIconClass = 'text-white';

    const menuItems = [
        {
            title: 'Dashboard',
            path: '/manager-console',
            icon: <MdDashboard size={24} className={menuIconClass} />
        },
        {
            title: 'Grades Summary',
            path: '/manager-console/grades-summary',
            icon: <MdInsertChart size={24} className={menuIconClass} />
        },
        {
            title: 'Grades - Detailed',
            path: '/manager-console/grades-detailed',
            icon: <MdBarChart size={24} className={menuIconClass} />
        },
        {
            title: 'Labs',
            path: '/manager-console/labs',
            icon: <MdScience size={24} className={menuIconClass} />
        },
        {
            title: 'Courses',
            path: '/manager-console/courses',
            icon: <MdDescription size={24} className={menuIconClass} />
        }
    ];

    return (
        <Sider width={250} className="z-50">
            <Col className="fixed h-screen w-[250px] bg-primary">
                <Row className="h-[90px] items-center gap-2 bg-primary p-4 drop-shadow-xl">
                    <AiFillBank
                        size={40}
                        className="cursor-pointer text-white"
                        onClick={() => navigate('/manager-console')}
                    />
                    <Text
                        className="cursor-pointer text-sm font-semibold uppercase leading-tight text-white"
                        onClick={() => navigate('/manager-console')}>
                        People Manager<br></br> Console
                    </Text>
                </Row>

                <Col className="p-6">
                    {/* <Select
                        className="mb-4 w-full"
                        defaultValue="Gcash FTE Program"
                        options={[
                            { value: 'gfte-289', label: 'Gcash FTE Program' },
                            { value: 'globe-000', label: 'Globe Cadetship' }
                        ]}
                    /> */}

                    <Col className="">
                        {menuItems.map((item, index) => {
                            return (
                                <Row
                                    key={index}
                                    className={`mb-1 flex h-auto cursor-pointer items-center gap-2 px-4 py-2 ${
                                        item.path === pathname
                                            ? ' bg-gradient-to-r from-[#0F3A8E] to-primary font-bold'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        navigate(item.path);
                                    }}>
                                    {item.icon}
                                    <Text className="text-md leading-none text-white">{item.title}</Text>
                                </Row>
                            );
                        })}
                    </Col>
                </Col>
            </Col>
        </Sider>
    );
};

export default ConsoleSidebar;
