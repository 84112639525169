import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Row, Typography, Grid } from 'antd';
import { getCoursesByUserPathways, getPathwaysByOrg, getCurrentLessonByUser } from '../../data/shared';
import { GetCourseModules } from '../../vendor/SpringboardAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../layout/loaders/PageLoader';
import '../../assets/css/home.css';
import CarouselSwiper from '../layout/CarouselSwiper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../utils/ToastError';

const startUserCourse = async (data) => {
    const startUserCourseMutation = `
        mutation StartUserCourse($input: StartCourseInput) {
            startUserCourse(input: $input) {
                id
                userId
                courseCode
                pathwayId
                title
                description
                coverPhotoUrl
                deps
                percent
                status
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: startUserCourseMutation,
        variables: {
            input: data
        }
    });
    return response.data.startUserCourse;
};

const CourseIntro = () => {
    const [courses, setCourses] = useState([]);
    const [enableStartCourse, setEnableStartCourse] = useState(false);
    const [courseIsStarting, setCourseIsStarting] = useState(false);
    const [modules, setModules] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [firstLessonId, setFirstLessonId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { Title } = Typography;
    const { slug } = useParams();
    const navigate = useNavigate();
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const location = useLocation();

    useEffect(() => {
        const fetch = async () => {
            let _currentLesson = await getCurrentLessonByUser(slug);
            if (_currentLesson) {
                setCurrentLesson(_currentLesson);
                navigate(`/course/${slug}/cc/${_currentLesson.id}`);
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            let _pathways = await getPathwaysByOrg();
            let activePathway = _pathways.find((pw) => pw.status === 'ACTIVATED');
            let _courses = await getCoursesByUserPathways(activePathway?.id);

            // SET OTHER COURSES - FOR OTHER COURSES SECTION ON THE BOTTOM OF PAGE
            setCourses(
                _courses?.map((crs) => {
                    return {
                        ...crs,
                        disabled: !isEnabled(parseFloat(crs.percent), crs.deps)
                    };
                })
            );

            const getCourseModules = await GetCourseModules(slug);

            setModules(getCourseModules.cycle);

            const firstModule = getCourseModules.cycle.sessions[0]?.sessionItems;
            firstModule.sort((a, b) => {
                let labelA = a.title.toLowerCase().split(':')[0];
                let labelB = b.title.toLowerCase().split(':')[0];

                if (labelA < labelB) {
                    return -1;
                }
                if (labelA > labelB) {
                    return 1;
                }
                return 0;
            });
            setFirstLessonId(flattenedSessionItemsToLesson(getCourseModules?.cycle?.sessions)[0]?.id);

            setLessons(flattenedSessionItemsToLesson(getCourseModules.cycle.sessions));
            if (getCourseModules.cycle.sessions.length > 0) {
                setEnableStartCourse(true);
            }
            setIsLoading(false);
        };

        if (!currentLesson) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLesson, location]);

    const isEnabled = (percent, deps) => {
        if (deps.length === 0 || percent === 100) {
            return true;
        }
    };

    const flattenedSessionItemsToLesson = (sessions) => {
        let seenIds = new Set();

        return sessions.reduce((accumulator, currentSession) => {
            const modifiedItems = currentSession.sessionItems
                .map((item) => ({
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    typename: item.__typename
                }))
                .filter((item) => {
                    // making sure that lessons has type Lab only
                    // type Quiz and Assignment will be handles seperately
                    if (item.typename !== 'Lab') {
                        return false;
                    }

                    if (seenIds.has(item.id)) {
                        return false;
                    }

                    seenIds.add(item.id);
                    return true;
                });

            return accumulator.concat(modifiedItems);
        }, []);
    };

    const startCourse = async () => {
        // EXPLICITLY CONVERT JSON TO JSON STRING FOR GRAPHQL
        if (lessons.length <= 0) {
            // showNoLessonsToast();
            showErrorToast({ message: 'No lessons available for this course yet. Redirecting to home.' });
            setTimeout(() => {
                navigate('/');
            }, 3000);
            // return null;
        }

        const data = {
            lessonData: JSON.stringify(lessons),
            courseId: slug,
            currentLessonId: firstLessonId
        };

        if (!currentLesson) {
            setCourseIsStarting(true);
            await startUserCourse(data);
            setCourseIsStarting(false);
            navigate(`/course/${slug}/cc/${firstLessonId}`);
        } else {
            navigate(`/course/${slug}/cc/${currentLesson.id}`);
        }
    };

    return (
        <>
            {isLoading ? (
                <PageLoader />
            ) : (
                <Row className={`${xs ? 'p-6' : 'p-12'} mx-auto flex  max-w-[1920px] flex-col`}>
                    <div className="bg-circle" style={{ opacity: '0.05' }}></div>
                    <div className="globe-vector" style={{ opacity: '0.1' }}></div>
                    <Title>{modules?.title}</Title>
                    <div className="text-lg">
                        {modules?.description?.split('\n\n').map((par, index) => {
                            return (
                                <p className="mb-4" key={index}>
                                    {par}
                                </p>
                            );
                        })}
                    </div>
                    <Row className="mt-8 gap-4">
                        {enableStartCourse && (
                            <Button type="primary" loading={courseIsStarting} onClick={() => startCourse()}>
                                Start course
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                navigate(`/`);
                            }}>
                            Go back to home
                        </Button>
                    </Row>

                    <Row className="mt-24 w-screen">
                        <Row className="flex w-full items-center justify-between">
                            <h4 className="mb-2 self-start text-lg font-semibold text-primary">Other Courses</h4>
                        </Row>
                        {courses.length !== 0 && (
                            <div className=" flex w-full">
                                <CarouselSwiper slides={courses?.filter((item) => item.id !== slug)} bgPrimary={true} />
                            </div>
                        )}
                    </Row>
                </Row>
            )}
            <ToastContainer />
        </>
    );
};

export default CourseIntro;
