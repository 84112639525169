import { Col, Row, Typography, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PeopleManagerProviderContext } from '../../../providers/PeopleManagerProvider';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../../../utils/ApiGwOptions';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { staging as stagingCourses } from '../../../data/PathwayCoursesStaging';
import { prod as prodCourses } from '../../../data/PathwayCoursesProd';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

export const typeOptions = [
    { value: 'exams', label: 'Exams' },
    { value: 'quizzes', label: 'Quizzes' }
];

export const GradesSummary = () => {
    const { peopleMgrState, peopleMgrDispatch } = useContext(PeopleManagerProviderContext);
    const { quizzesAndExams } = peopleMgrState;
    const location = useLocation();

    const [displayData, setDisplayData] = useState([]);
    const [typeFilter, setTypeFilter] = useState(['exams', 'quizzes']);
    const [courseFilter, setCourseFilter] = useState(
        (process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses).map((item) => item.code)
    );
    const pathwayCoursesData = process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses;

    useEffect(() => {
        if (token && quizzesAndExams.length === 0) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getLearnersQuizzesAndExams`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    data.grades = data.grades?.sort((a, b) => {
                        const lastNameA = a.name.split(' ').pop();
                        const lastNameB = b.name.split(' ').pop();
                        return lastNameA.localeCompare(lastNameB);
                    });
                    peopleMgrDispatch({
                        type: 'SAVE_QUIZZES',
                        payload: data.grades
                    });

                    setDisplayData(data.grades);
                }
            };

            getLearnersQuizzesAndExams();
        }
    }, []);

    useEffect(() => {
        setDisplayData(quizzesAndExams);
    }, [location, quizzesAndExams]);

    const handleNameFilter = (selectedNames) => {
        if (selectedNames.length !== 0) {
            setDisplayData(quizzesAndExams.filter((x) => selectedNames.includes(x.name)));
        } else {
            setDisplayData(quizzesAndExams);
        }
    };
    const handleTypeFilter = (selectedTypes) => {
        const allTypes = ['exams', 'quizzes'];
        if (selectedTypes.length !== 0) {
            setTypeFilter(selectedTypes);
        } else {
            setTypeFilter(allTypes);
        }
    };
    const handleCourseFilter = (selectedCourses) => {
        const allCourses = (process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses).map(
            (item) => item.code
        );
        if (selectedCourses.length !== 0) {
            setCourseFilter(selectedCourses);
        } else {
            setCourseFilter(allCourses);
        }
    };

    const LearnerRow = (item, index) => {
        const getAverage = (data) => {
            const parsedData = JSON.parse(data);

            const coursesToInclude = courseFilter?.map((item) => {
                const foundCourse = pathwayCoursesData.filter((pw) => pw.code === item)[0];
                return foundCourse?.id;
            });

            const filteredCourses = Object.entries(parsedData)
                .filter(([course]) => coursesToInclude.includes(course))
                .map(([_, courseGrades]) => courseGrades);

            const scores = filteredCourses.flatMap((courseGrades) =>
                courseGrades.map(({ score, totalItems }) => (totalItems === 0 ? 0 : (score / totalItems) * 100))
            );

            const sum = scores.reduce((acc, curr) => acc + curr, 0);
            const average = scores.length > 0 ? sum / scores.length : 0;
            return average;
        };

        const quiz = +getAverage(item.quizzes);
        const exam = +getAverage(item.exams);
        const overall = (quiz + exam) / 2;

        return (
            <tr key={index}>
                <td>
                    {index + 1}. {item.name}
                </td>
                {typeFilter.length === 2 && <td>{overall.toFixed(2)}</td>}
                {typeFilter.includes('quizzes') && <td>{quiz.toFixed(2)}</td>}
                {typeFilter.includes('exams') && <td>{exam.toFixed(2)}</td>}
            </tr>
        );
    };

    return (
        <>
            {displayData?.length === 0 ? (
                <CourseLoader isLesson={false} />
            ) : (
                <Col className="w-full">
                    <Title style={{ marginTop: '0px' }}>Grades - Summary</Title>
                    <Row className="mb-8 w-full">
                        <Select
                            allowClear
                            placeholder="Filter by Name"
                            onChange={handleNameFilter}
                            mode="multiple"
                            className="select-overflow multi-filter w-1/3 pr-4">
                            {quizzesAndExams.map((user, index) => {
                                return (
                                    <Option value={user.name} key={index}>
                                        {user.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Select
                            allowClear
                            placeholder="Filter by Type"
                            onChange={handleTypeFilter}
                            mode="multiple"
                            className="select-overflow multi-filter w-1/3 pr-4">
                            {typeOptions.map((type, index) => {
                                return (
                                    <Option value={type.value} key={index}>
                                        {type.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Select
                            allowClear
                            placeholder="Filter by Course"
                            onChange={handleCourseFilter}
                            mode="multiple"
                            className="select-overflow  w-1/3 ">
                            {pathwayCoursesData.map((course, index) => {
                                return (
                                    <Option value={course.code} key={index}>
                                        {course.code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Row>
                    <Row className=" ">
                        <table className="w-full">
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th className="w-[40%]">Name</th>
                                    {typeFilter.length === 2 && <th className="w-[20%]">Overall Grade</th>}
                                    {typeFilter.includes('quizzes') && <th className="w-[20%]">Quiz</th>}
                                    {typeFilter.includes('exams') && <th className="w-[20%]">Exam</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {displayData.map((item, index) => {
                                    return LearnerRow(item, index);
                                })}
                            </tbody>
                        </table>
                    </Row>
                </Col>
            )}
        </>
    );
};

export default GradesSummary;
