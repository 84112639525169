import React from 'react';
import { Typography, Radio, Space, Checkbox } from 'antd';
import ReactMarkdown from 'react-markdown';

const { Paragraph } = Typography;

const QuestionComponent = ({ questions, displayedIndex, answers, setAnswers }) => {
    const onAnswerChange = (event) => {
        const newAnswers = [...answers];
        const answerObj = {
            questionCode: questions[displayedIndex].questionCode,
            answer: [event.target.value]
        };
        newAnswers[displayedIndex] = answerObj;
        setAnswers(newAnswers);
    };

    const onAnswerChangeMultiple = (checkedValues) => {
        const newAnswers = [...answers];
        const answerObj = {
            questionCode: questions[displayedIndex].questionCode,
            answer: checkedValues
        };
        newAnswers[displayedIndex] = answerObj;
        setAnswers(newAnswers);
    };

    const question = questions[displayedIndex];

    return (
        <React.Fragment>
            <Paragraph style={{ marginTop: '40px' }}>
                <span>
                    <ReactMarkdown>{parseInt(displayedIndex) + 1 + '. ' + question.questionMd}</ReactMarkdown>
                </span>
            </Paragraph>
            <Paragraph style={{ marginTop: '40px' }}>
                {question.responseType === 'MULTIPLE' ? (
                    <Checkbox.Group onChange={onAnswerChangeMultiple} value={answers[displayedIndex]?.answer}>
                        <Space direction="vertical">
                            {question.choices.map((item, index) => (
                                <Checkbox key={index} value={item.questionChoiceMd}>
                                    {item.questionChoiceMd}
                                </Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                ) : (
                    <Radio.Group
                        onChange={onAnswerChange}
                        value={
                            answers[displayedIndex]?.answer && answers[displayedIndex]?.answer.length
                                ? answers[displayedIndex]?.answer[0]
                                : null
                        }>
                        <Space direction="vertical">
                            {question.choices.map((item, index) => (
                                <Radio key={index} value={item.questionChoiceMd}>
                                    {item.questionChoiceMd}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                )}
            </Paragraph>
            {/* <Paragraph>
                <pre>{JSON.stringify(answers[displayedIndex], null, 2)}</pre>
                <pre>{JSON.stringify(question, null, 2)}</pre>
            </Paragraph> */}
        </React.Fragment>
    );
};

export default QuestionComponent;
