import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row, Select, Typography } from 'antd';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../../../utils/ApiGwOptions';
import { PeopleManagerProviderContext } from '../../../providers/PeopleManagerProvider';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const Labs = () => {
    const { peopleMgrState, peopleMgrDispatch } = useContext(PeopleManagerProviderContext);
    const { labs } = peopleMgrState;
    const location = useLocation();

    const [chartData, setChartData] = useState({
        names: [],
        doneLabs: [],
        pendingLabs: []
    });
    const [nameFilter, setNameFilter] = useState([]);

    useEffect(() => {
        if (token && labs.length === 0) {
            const getLearnersLabs = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getLearnersLabs`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    data.grades = data.grades?.sort((a, b) => {
                        const lastNameA = a.name.split(' ').pop();
                        const lastNameB = b.name.split(' ').pop();
                        return lastNameA.localeCompare(lastNameB);
                    });
                    peopleMgrDispatch({
                        type: 'SAVE_LABS',
                        payload: data.grades
                    });

                    setNameFilter(data.grades.map((item) => item.name));
                }
            };

            getLearnersLabs();
        }
    }, [token]);

    useEffect(() => {
        setNameFilter(labs.map((item) => item.name));
    }, [location]);

    useEffect(() => {
        if (labs) {
            const grades = labs;

            const namesArray = grades
                .filter((grade) => nameFilter.includes(grade.name))
                .map((item) => {
                    return item.name;
                });
            const doneLabsArray = [];
            const pendingLabsArray = [];
            grades
                .filter((grade) => nameFilter.includes(grade.name))
                .map((item) => {
                    const extractDoneLabs = (item) => {
                        const parsedData = JSON.parse(item.labs);

                        let done = 0;
                        let pending = 0;

                        const flattenedLessons = Object.values(parsedData).flatMap((items) => items);

                        flattenedLessons.map((data) => {
                            if (data.score === 100) {
                                done++;
                            } else {
                                pending++;
                            }
                        });
                        doneLabsArray.push(done);
                        pendingLabsArray.push(pending);
                    };
                    extractDoneLabs(item);
                });
            setChartData((prev) => ({
                ...prev,
                names: namesArray,
                doneLabs: doneLabsArray,
                pendingLabs: pendingLabsArray
            }));
        }
    }, [labs, nameFilter]);

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                offsetY: -5
            },
            animations: {
                speed: 500,
                easing: 'easeinout'
                // enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        colors: ['#032EB7', '#6b7380'],
        // title: {
        //     text: 'Student Activities'
        // },
        xaxis: {
            categories: chartData?.names,
            labels: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            offsetX: 118
        }
    };

    const series = [
        {
            name: 'Done',
            data: chartData.doneLabs
        },
        {
            name: 'Pending',
            data: chartData.pendingLabs
        }
    ];

    const handleNameFilter = (selectedNames) => {
        const allNames = labs.map((item) => item.name);
        if (selectedNames.length !== 0) {
            setNameFilter(selectedNames);
        } else {
            setNameFilter(allNames);
        }
    };
    return (
        <>
            {chartData.names.length === 0 ? (
                <CourseLoader isLesson={false} />
            ) : (
                <Col className="w-full">
                    <Title style={{ marginTop: '0px' }}>Lab Submissions</Title>
                    <Row className="mb-4 gap-4">
                        <Select
                            allowClear
                            placeholder="Filter by Name"
                            onChange={handleNameFilter}
                            mode="multiple"
                            className="select-overflow w-full min-w-[400px]">
                            {labs.map((user, index) => {
                                return (
                                    <Option value={user.name} key={index}>
                                        {user.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Row>
                    <Chart options={options} series={series} type="bar" height={1000} />
                </Col>
            )}
        </>
    );
};

export default Labs;
