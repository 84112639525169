import React from 'react';
import ConsoleSidebar from './Sidebar';
import ConsoleHeader from './Header';
import { Col, Layout } from 'antd';
import { useLocation } from 'react-router-dom';

const ConsoleLayout = ({ children }) => {
    const { pathname } = useLocation();
    const childPath = pathname.split('/')[2];

    return (
        <Layout className="manager-console flex w-screen">
            {childPath !== 'register' && (
                <>
                    <ConsoleSidebar />
                    <Col className="w-full">
                        <ConsoleHeader />
                        <Col className=" mt-[90px] w-full p-10">{children}</Col>
                    </Col>
                </>
            )}

            {childPath === 'register' && <Col>{children}</Col>}
        </Layout>
    );
};

export default ConsoleLayout;
