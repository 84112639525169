import React, { useState } from 'react';
import MarkdownWrapper from '../../layout/MarkdownWrapper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Button } from 'antd';
import { createAssignment, getAssignment } from '../../../data/shared';
import { useNavigate, useParams } from 'react-router-dom';

import CourseLoader from '../../layout/loaders/CourseLoader';

//markdown plugins
// import rehypeHighlight from 'rehype-highlight';
// import remarkGfm from 'remark-gfm';
// import rehypeSlug from 'rehype-slug';
// import rehypeRaw from 'rehype-raw';

const AssignmentContent = ({ assignmentInfo, assignmentContent, setAssignmentInfo, setAssignmentContent }) => {
    const [loading, setLoading] = useState(false);

    const { contentId, slug } = useParams();
    const navigate = useNavigate();

    const getAssignmentInput = {
        assignmentId: contentId,
        courseId: slug
    };

    const startAssignment = async () => {
        setLoading(true);
        const startAssignmentInput = {
            assignmentId: contentId,
            courseId: slug,
            title: assignmentContent.title
        };

        await createAssignment(startAssignmentInput);
        const _assignmentInfo = await getAssignment(getAssignmentInput);
        setAssignmentInfo(_assignmentInfo);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            {loading ? (
                <CourseLoader />
            ) : (
                <>
                    <h1 className="mb-5 text-5xl font-semibold">{assignmentContent?.title}</h1>
                    {!assignmentInfo ? (
                        <div className="h-[300px]">
                            <h3 className="my-5 text-2xl font-semibold">{assignmentContent?.description}</h3>
                            <div className={`mb-5 flex  gap-2 `}>
                                <Button
                                    style={{}}
                                    size="large"
                                    onClick={() => {
                                        navigate(`/`);
                                    }}>
                                    Go back to home
                                </Button>
                                <Button
                                    className="bg-primary text-white"
                                    size="large"
                                    loading={loading}
                                    onClick={() => {
                                        startAssignment();
                                    }}>
                                    Start Assignment
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <MarkdownWrapper>
                            <ReactMarkdown
                            // remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeHighlight, rehypeSlug]}
                            >
                                {assignmentContent?.contentMd}
                            </ReactMarkdown>
                        </MarkdownWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default AssignmentContent;
