import { Col, Row, Grid } from 'antd';
import React from 'react';

const OverviewTab = ({ currentLesson }) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    return (
        <Row
            className={`${
                xs ? 'w-full' : 'min-w-[40vw] max-w-[60vw]'
            } mx-auto my-20 min-h-[250px] justify-center border border-solid border-slate-200 pb-8`}>
            <Col className="mt-12 px-9 text-center">
                <h4 className="mb-5 text-lg font-semibold text-primary">Overview</h4>
                <h4 className="text-gray-600">
                    {currentLesson?.description ? currentLesson?.description : currentLesson?.title}
                </h4>
            </Col>
        </Row>
    );
};

export default OverviewTab;
