import React from 'react';
import { Layout } from 'antd';
import CoursePageHeader from '../components/layout/CoursePageHeader';
import '../assets/css/course.css';
import { Outlet } from 'react-router-dom';

const CoursePage = () => {
    return (
        <>
            <Layout className="relative bg-white">
                <CoursePageHeader hideWelcome={true} primaryBg={true} />
                <Outlet></Outlet>
            </Layout>
        </>
    );
};

export default CoursePage;
