import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Card } from 'antd';
import { GetQuizAPI } from '../vendor/SpringboardAPI';
import {
    getAssessment,
    startAssessmentQuery,
    enrollUser,
    submitAssessmentAnswersQuery,
    createCoursesByUsers
} from '../data/shared';

import SignOutButton from '../components/layout/ButtonSignOut';
import PageLoader from '../components/layout/loaders/PageLoader';

// Providers
import { AssessQuizProvider } from '../providers/AssessQuizProvider';
import { UserProviderContext } from '../providers/UserProvider';

import Logo from '../assets/logo.png';
import QuizSection from '../components/assessment/QuizSection';

const { Title, Paragraph } = Typography;

const AssessmentQuizPage = () => {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [assessment, setAssessment] = useState(null);
    const [quiz, setQuiz] = useState(null);
    const [displayedIndex, setDisplayedIndex] = useState(0);
    const [started, setStarted] = useState(false);
    const [answers, setAnswers] = useState(Array(quiz?.questions.length).fill([]));
    const { userDispatch, userState } = useContext(UserProviderContext);

    // HANDLE API CALL FOR START ATTEMPT
    const handleStart = () => {
        const fetch = async () => {
            setBtnLoading(true);
            const result = await startAssessmentQuery(assessment.id);
            setAssessment(result.startAssessment);
            setStarted(true);
            setBtnLoading(false);
        };
        fetch();
    };

    // HANDLE CONTINUE BUTTON ON PATHWAY
    /* eslint-disable */
    const handleContinue = () => {
        const fetch = async () => {
            setBtnLoading(true);
            // SET USER COURSES;
            await createCoursesByUsers();

            await enrollUser();
            userDispatch({ type: 'SET_API_USER', payload: { ...userState.apiUser, enrollmentStatus: 'ENROLLED' } });
            // SET USER DATE ON USER PROVIDER
        };
        fetch();
    };
    /* eslint-enable */

    // %%% START ON THIS SECTION: This is where all the LOGIC STARTED. %%%
    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            // call all async http request
            const getAssessmentResult = await getAssessment();
            let _assessment = getAssessmentResult.getAssessment;
            setAssessment(_assessment);

            const getQuizResult = await GetQuizAPI(_assessment.quizId);
            let _quiz = getQuizResult.quiz;
            setQuiz(_quiz);

            let dl = computeDeadline(_quiz, _assessment);

            if (dl === 0) {
                const submitAssessmentAnswersResult = await submitAssessmentAnswersQuery(_assessment.id, []);
                _assessment = submitAssessmentAnswersResult.submitAssessmentAnswers;
                setAssessment(_assessment);
            }

            if (_assessment.status === 'STARTED') setStarted(true);
            setLoading(false);
        };
        fetch();
    }, []);

    // HANDLE FOR SETTING TIMER PER ATTEMPT
    const computeDeadline = (qz, as) => {
        let startTime = as.mostRecentAttempt?.timeStarted;
        let quizDuration = qz.durationMins * 60000 || 60 * 60000;

        const nowDiff = Date.now() - startTime;
        const remaining = quizDuration - nowDiff;

        if (remaining <= 0) {
            return 0;
        }

        return Date.now() + remaining;
    };

    // HANDLER WHEN TIMER ENDS
    const handleTimeCompleted = () => {
        submitQuiz();
    };

    // HANDLER WHEN QUIZ IS LAST ITEM
    const isLastIndex = () => {
        if (!quiz) return;
        const isLast = displayedIndex === quiz.questions.length - 1;
        return isLast;
    };

    // HANDLER WHEN USER SUBMIT QUIZ AND END EXAM
    const submitQuiz = () => {
        const fetchApi = async () => {
            setBtnLoading(true);
            const submitAssessmentAnswersResult = await submitAssessmentAnswersQuery(assessment.id, answers);
            let _assessment = submitAssessmentAnswersResult.submitAssessmentAnswers;
            setAssessment(_assessment);
            setBtnLoading(false);
        };
        fetchApi();
    };

    const navigateQuestionItem = (index) => {
        setDisplayedIndex(index);
    };

    const confirm = (event) => {
        handleStart();
    };

    const cancel = (event) => {
        return null;
    };

    const QuizIntroSection = () => {
        return (
            <React.Fragment>
                <Title>{quiz.title}</Title>
                <Title level={5}>{quiz.description}</Title>
                <Title level={3}>Instructions</Title>
                <Paragraph>
                    Time allowed: <span style={{ fontWeight: 'bold' }}>{quiz.durationMins} min(s)</span>
                </Paragraph>
                <Paragraph>
                    Attempts: Allowed - {quiz.triesCount}, Completed -{' '}
                    {!assessment.attempts ? 0 : assessment.attempts.length}
                </Paragraph>
                <Paragraph>
                    <span style={{ fontWeight: 'bold', color: 'red' }}>Note: </span>
                    You are not allowed to leave the page or refresh the browser while the assessment exam is ongoing.
                    Please ensure you have completed the assessment exam before leaving the page.
                </Paragraph>
                <Paragraph>
                    Once the allocated time period that was set for this quiz expires, you are required to submit your
                    assessment exam response.
                </Paragraph>
                <Paragraph>
                    <span style={{ fontWeight: 'bold', color: 'red' }}>Note: </span>
                    Any responses entered after the time limit expires will not be submitted.
                </Paragraph>
                <Paragraph>Click "Start Assessment" to begin Attempt 1</Paragraph>
                <Paragraph>
                    {/* <Button size="large" type="primary" loading={btnLoading} onClick={() => handleStart()}>
                        Start Assessment
                    </Button> */}
                    <Popconfirm
                        title="Proceed to Assessment Exam"
                        description="Are you sure to proceed?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No">
                        <Button size="large" type="primary" loading={btnLoading}>
                            Start Assessment
                        </Button>
                    </Popconfirm>
                </Paragraph>
            </React.Fragment>
        );
    };

    const QuizCompleteSection = () => {
        return (
            <React.Fragment>
                <Col xs={24} sm={24} md={20} lg={16} xl={16} style={{ padding: '40px' }}>
                    <Title level={4} className="flex items-center">
                        <img src={Logo} alt="Logo" className="medium-logo" /> Technical Diagnostic Exam Result
                    </Title>
                    <Card
                        bordered={false}
                        className="rounded-t-none border-t-8 border-solid border-primary"
                        style={{ width: '100%', textAlign: 'center' }}>
                        <Title level={5}>
                            Congratulations! You have completed the assessment. Please wait for the individual results
                            to be released by GCash Fintech University.
                        </Title>
                        <Title level={3}>Fintech Talent Engine</Title>

                        <div className="flex justify-center">
                            <img className="w-[300px]" src={Logo} alt="Logo" />
                        </div>
                        {/* HIDE FOR PROD */}
                        <Paragraph className="align-right" style={{ marginTop: '20px' }}>
                            <Button
                                style={{
                                    marginTop: '40px',
                                    alignItems: 'center'
                                }}
                                size="large"
                                type="primary"
                                onClick={() => handleContinue()}
                                loading={btnLoading}>
                                Continue to your pathway
                            </Button>
                        </Paragraph>
                    </Card>
                </Col>
            </React.Fragment>
        );
    };

    return !loading ? (
        <React.Fragment>
            <SignOutButton type="link" size="large" />
            <Row justify="center" align="middle" style={{ minHeight: '50vh' }}>
                {assessment.status === 'COMPLETED' ? (
                    <QuizCompleteSection></QuizCompleteSection>
                ) : (
                    <Col xs={24} sm={24} md={20} lg={16} xl={16} style={{ padding: '40px' }}>
                        <AssessQuizProvider>
                            {started ? (
                                <QuizSection
                                    quiz={quiz}
                                    computeDeadline={computeDeadline}
                                    assessment={assessment}
                                    handleTimeCompleted={handleTimeCompleted}
                                    displayedIndex={displayedIndex}
                                    loading={loading}
                                    navigateQuestionItem={navigateQuestionItem}
                                    answers={answers}
                                    isLastIndex={isLastIndex}
                                    cancel={cancel}
                                    btnLoading={btnLoading}
                                    setAnswers={setAnswers}
                                    submitQuiz={submitQuiz}
                                />
                            ) : (
                                <QuizIntroSection />
                            )}
                        </AssessQuizProvider>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    ) : (
        <PageLoader message="Please wait while we are loading your resources..." />
    );
};

export default AssessmentQuizPage;
