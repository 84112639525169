import React, { useContext } from 'react';
import { Typography, Form, Input, Button, Col, Card } from 'antd';

import { UserProviderContext } from '../../providers/UserProvider';

import Logo from '../../assets/logo.png';

const { Title } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 }
};
const tailLayout = {
    wrapperCol: {
        xs: 24,
        sm: 24,
        md: { offset: 16, span: 8 },
        lg: { offset: 16, span: 8 },
        xl: { offset: 16, span: 8 }
    }
};

const AboutCandidateForm = ({ setContinue }) => {
    const { userState, userDispatch } = useContext(UserProviderContext);

    const onFinish = (values) => {
        setContinue(true);
        userDispatch({ type: 'SET_API_USER', payload: { ...values, enrollmentStatus: 'FOR_ASSESSMENT' } });
        return values;
    };

    const initValues = {
        name: userState.cognitoUser?.name
    };

    return (
        <Col xs={24} sm={24} md={20} lg={12} xl={12} style={{ padding: '20px' }}>
            <img src={Logo} alt="Logo" className="medium-logo" />
            <Card
                bordered={false}
                style={{ width: '100%' }}
                className="rounded-t-none border-t-8 border-solid border-primary">
                <Title level={5}>Tell me about yourself</Title>
                <Form
                    {...layout}
                    initialValues={initValues}
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                    autoComplete="off">
                    <Form.Item
                        label="My name is"
                        name="name"
                        rules={[{ required: true, message: 'This is a required field.' }]}>
                        <Input size="large" placeholder="Fullname" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label="I am currently a"
                        name="currentJob"
                        rules={[{ required: true, message: 'This is a required field.' }]}>
                        <Input size="large" placeholder="Programmer" />
                    </Form.Item>

                    <Form.Item
                        label="What track are you interested in?"
                        name="wantedPath"
                        rules={[{ required: true, message: 'This is a required field.' }]}>
                        <Input size="large" placeholder="Solutions Architect" />
                        {/* <Select placeholder="Select a track" allowClear>
                            <Option value="Solution Architect">Solution Architect</Option>
                            <Option value="DevSecOps Engineer">DevSecOps Engineer</Option>
                            <Option value="Full Stack Developer">Full Stack Developer</Option>
                            <Option value="Agile Coach">Agile Coach</Option>
                        </Select> */}
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" size="large" htmlType="submit" shape="round" className="signin">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Col>
    );
};

export default AboutCandidateForm;
