import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './providers/AuthProvider';
import { UserProvider } from './providers/UserProvider';

import ProtectedRoute from './components/auth/ProtectedRoute';
import Signin from './pages/UserSignin';

import HomePage, { Dashboard } from './pages/Home';
import CoursePage from './pages/Course';
import Pathway from './pages/Pathway';

import CourseIntro from './components/course/CourseIntro';
import CourseContent from './components/course/CourseContent';
import ManagerConsole from './pages/ManagerConsole';
import ManagerDashboard from './components/manager/pages/Dashboard';
import GradesSummary from './components/manager/pages/GradesSummary';
import GradesDetailed from './components/manager/pages/GradesDetailed';
import ManagerLabs from './components/manager/pages/Labs';
import ManagerCourses from './components/manager/pages/Courses';
import Register from './components/manager/pages/Register';

export const routeConfig = {
    signIn: {
        path: '/signin',
        component: () => {
            return <Signin />;
        }
    },
    home: {
        path: '/',
        component: () => {
            return <HomePage />;
        },
        dashboard: {
            component: () => {
                return (
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                );
            }
        },
        course: {
            path: 'course/:slug',
            component: () => {
                return (
                    <ProtectedRoute>
                        <CoursePage />
                    </ProtectedRoute>
                );
            },
            courseIntro: {
                component: () => {
                    return (
                        <ProtectedRoute>
                            <CourseIntro />
                        </ProtectedRoute>
                    );
                }
            },
            courseContent: {
                path: 'cc/:contentId',
                component: () => {
                    return (
                        <ProtectedRoute>
                            <CourseContent />
                        </ProtectedRoute>
                    );
                }
            }
        },
        pathway: {
            path: 'pathway/:slug',
            component: () => {
                return (
                    <ProtectedRoute>
                        <Pathway />
                    </ProtectedRoute>
                );
            }
        }
    },
    managerConsole: {
        path: '/manager-console',
        component: () => {
            return <ManagerConsole />;
        },
        dashboard: {
            component: () => {
                return (
                    <ProtectedRoute>
                        <ManagerDashboard />
                    </ProtectedRoute>
                );
            }
        },
        gradesSummary: {
            path: 'grades-summary',
            component: () => {
                return (
                    <ProtectedRoute>
                        <GradesSummary />
                    </ProtectedRoute>
                );
            }
        },
        gradesDetailed: {
            path: 'grades-detailed',
            component: () => {
                return (
                    <ProtectedRoute>
                        <GradesDetailed />
                    </ProtectedRoute>
                );
            }
        },
        labs: {
            path: 'labs',
            component: () => {
                return (
                    <ProtectedRoute>
                        <ManagerLabs />
                    </ProtectedRoute>
                );
            }
        },
        courses: {
            path: 'courses',
            component: () => {
                return (
                    <ProtectedRoute>
                        <ManagerCourses />
                    </ProtectedRoute>
                );
            }
        },
        register: {
            path: 'register',
            component: () => {
                return (
                    // <ProtectedRoute>
                    <Register />
                    // </ProtectedRoute>
                );
            }
        }
    }
};

const App = () => {
    return (
        <Router>
            <UserProvider>
                <AuthProvider>
                    <Routes>
                        {/* Learner Routes */}
                        <Route path={routeConfig.home.path} element={routeConfig.home.component()}>
                            <Route index element={routeConfig.home.dashboard.component()} />
                            <Route path={routeConfig.home.course.path} element={routeConfig.home.course.component()}>
                                <Route index element={routeConfig.home.course.courseIntro.component()} />
                                <Route
                                    path={routeConfig.home.course.courseContent.path}
                                    element={routeConfig.home.course.courseContent.component()}
                                />
                            </Route>
                            <Route
                                path={routeConfig.home.pathway.path}
                                element={routeConfig.home.pathway.component()}
                            />
                        </Route>

                        {/* People Manager Console Routes */}

                        <Route path={routeConfig.managerConsole.path} element={routeConfig.managerConsole.component()}>
                            <Route index element={routeConfig.managerConsole.dashboard.component()}></Route>
                            <Route
                                path={routeConfig.managerConsole.gradesSummary.path}
                                element={routeConfig.managerConsole.gradesSummary.component()}></Route>
                            <Route
                                path={routeConfig.managerConsole.gradesDetailed.path}
                                element={routeConfig.managerConsole.gradesDetailed.component()}></Route>
                            <Route
                                path={routeConfig.managerConsole.labs.path}
                                element={routeConfig.managerConsole.labs.component()}></Route>
                            <Route
                                path={routeConfig.managerConsole.courses.path}
                                element={routeConfig.managerConsole.courses.component()}></Route>
                            <Route
                                path={routeConfig.managerConsole.register.path}
                                element={routeConfig.managerConsole.register.component()}></Route>
                        </Route>

                        {/* Sign-in */}
                        <Route path={routeConfig.signIn.path} element={routeConfig.signIn.component()} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </AuthProvider>
            </UserProvider>
        </Router>
    );
};

export default App;
