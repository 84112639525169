import React, { useState } from 'react';
import MarkdownWrapper from '../../layout/MarkdownWrapper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Button } from 'antd';
import { startUserLesson, createUserLesson } from '../../../data/shared';
import { useNavigate } from 'react-router-dom';

const MarkdownContent = ({ lessonStartTime, setLessonStartTime, currentLesson, setCurrentLesson }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const startLesson = async () => {
        setLoading(true);

        if (currentLesson.insert) {
            await createUserLesson({
                lessonId: currentLesson.id,
                courseId: currentLesson.courseId,
                title: currentLesson.title
            });
        }

        const data = {
            courseId: currentLesson.courseId,
            lessonToStartId: currentLesson.id
        };

        const newLessonData = await startUserLesson(data);

        const timestamp = parseInt(!newLessonData.timeStarted ? 0 : newLessonData.timeStarted);

        setCurrentLesson({
            ...currentLesson,
            status: newLessonData.status,
            timeStarted: timestamp
        });

        setLessonStartTime(() => Date.now(timestamp));
        setLoading(false);
    };

    return (
        <>
            <h1 className="mb-5 text-5xl font-semibold">{currentLesson.title}</h1>
            {currentLesson?.status === 'NOT_STARTED' && (
                <div className="h-[300px]">
                    <h3 className="mt-5 text-2xl font-semibold">
                        You are given {currentLesson.durationMins} minutes to complete this lesson.
                    </h3>

                    <div className={`mt-4 flex gap-2 `}>
                        <Button
                            style={{}}
                            size="large"
                            onClick={() => {
                                navigate(`/`);
                            }}>
                            Go back to home
                        </Button>
                        <Button
                            className="bg-primary text-white"
                            loading={loading}
                            size="large"
                            onClick={() => {
                                startLesson();
                            }}>
                            Start lesson
                        </Button>
                    </div>
                </div>
            )}

            <MarkdownWrapper>
                {(lessonStartTime || currentLesson?.status !== 'NOT_STARTED') && (
                    <ReactMarkdown>{currentLesson?.bodyMd}</ReactMarkdown>
                )}
            </MarkdownWrapper>
        </>
    );
};

export default MarkdownContent;
