import { Typography, Col, Row, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';

const PathwayCard = ({ image, title, disabled, slug }) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const { Text } = Typography;

    const navigate = useNavigate();

    const handleClick = () => {
        if (!disabled) {
            localStorage.setItem('pathway-slug', slug);
            localStorage.setItem('pathway-title', title);
            navigate(`/pathway/${slug}?t=${title}`);
        }
    };

    return (
        <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={6}
            className={`${xs ? 'max-w-full' : 'max-w-[360px]'}`}
            onClick={() => {
                handleClick();
            }}>
            <Row
                className={`rounded-none border-none ${
                    disabled ? 'cursor-not-allowed opacity-75 grayscale' : 'cursor-pointer'
                } relative `}>
                <img alt={title} src={image} className="h-full w-full" />
                <div
                    style={{
                        background: 'linear-gradient(to top, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 50%)'
                    }}
                    className="absolute top-0 flex h-full w-full items-end pb-3 pl-3">
                    <Text className="font-semibold text-white">{title}</Text>
                </div>
            </Row>
        </Col>
    );
};

export default PathwayCard;
