import { Row, Space, Progress, Col, Grid } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPathwaysByOrg, getCoursesByUserPathways } from '../../data/shared';
import isPercentInProg from '../../utils/isPercentInProg';
import CourseStatusLoader from '../layout/loaders/CourseStatusLoader';
import EmptyListIndicator from '../layout/EmptyListIndicator';

const InProgressContent = () => {
    const [courses, setCourses] = useState([]);
    const [inProgressCourses, setInProgressCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { useBreakpoint } = Grid;
    const { md, xs } = useBreakpoint();
    const navigate = useNavigate();

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            let _pathways = await getPathwaysByOrg();
            let activePathway = _pathways.find((pw) => pw.status === 'ACTIVATED');
            let _courses = await getCoursesByUserPathways(activePathway.id);
            setCourses(_courses);
            setIsLoading(false);
        };
        fetch();
    }, []);

    useEffect(() => {
        setInProgressCourses(() => {
            return courses.filter((item) => {
                return isPercentInProg(item.percent);
            });
        });
    }, [courses]);

    return (
        <Space direction="vertical" className="mt-12 w-full">
            <Col>
                {isLoading ? (
                    <CourseStatusLoader />
                ) : (
                    <>
                        {inProgressCourses?.length === 0 ? (
                            <EmptyListIndicator message={'You have no on-going courses.'} />
                        ) : (
                            <>
                                <h2 className="mb-4 text-xl font-semibold text-white ">In Progress</h2>
                                {inProgressCourses.map((item, index) => {
                                    return (
                                        <div className="bg-white" key={index}>
                                            <Row
                                                onClick={() => {
                                                    navigate(`/course/${item.id}`);
                                                }}
                                                style={{
                                                    background:
                                                        'linear-gradient(-320deg, rgba(100, 150, 200, 0.2), rgba(206, 157, 44, 0.3))'
                                                }}
                                                className="mb-4 cursor-pointer items-center justify-between p-4 ">
                                                <Row className={`gap-4 ${md ? 'w-[80%]' : 'w-full'}`}>
                                                    <Row className={xs ? 'h-full w-full' : 'h-[100px] w-[100px]'}>
                                                        <img
                                                            alt="course-cover"
                                                            src={item.coverPhotoUrl}
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </Row>
                                                    <Row
                                                        className={`items-center" flex flex-col justify-between ${
                                                            xs ? 'gap-2' : ''
                                                        } `}>
                                                        <h4 className="text-gray-500">Solutions Architect</h4>
                                                        <h2 className="text-lg font-semibold">{item.title}</h2>
                                                        <h4>You are currently working on this</h4>
                                                    </Row>
                                                </Row>
                                                <div className={`${xs ? 'mt-2' : ''}`}>
                                                    <Progress
                                                        type="circle"
                                                        percent={parseFloat(item.percent)}
                                                        size={md ? 80 : 40}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </Col>
        </Space>
    );
};

export default InProgressContent;
