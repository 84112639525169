import React, { createContext, useReducer } from 'react';

const initialState = { cognitoUser: null, apiUser: null, userCreated: false, loading: true };
export const UserProviderContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_COGNITO_USER':
            return { ...state, cognitoUser: action.payload, loading: false };
        case 'SET_API_USER':
            return { ...state, apiUser: action.payload, loading: false };
        case 'LOADING':
            return { ...state, loading: true };
        default:
            return state;
    }
};

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <UserProviderContext.Provider value={{ userState: state, userDispatch: dispatch }}>
            {children}
        </UserProviderContext.Provider>
    );
};
