import React from 'react';
import ReusableModal from '../../common/ReusableModal';

const LabResponseModal = ({
    completeLessonModalOpen,
    handleComplete,
    setcompleteLessonModalOpen,
    submitLabResponsePromptModalOpen,
    setSubmitLabResponsePromptModalOpen,
    handleCloseSubmitLabResponseModal,
    forceSubmitPromptModalOpen,
    completeLessonIsLoading,
    handleTimerExpires,
    navigate,
    sessionType
}) => {
    return (
        <>
            <ReusableModal
                open={completeLessonModalOpen}
                onOk={() => {
                    handleComplete();
                    setcompleteLessonModalOpen(false);
                }}
                onCancel={() => {
                    setcompleteLessonModalOpen(false);
                }}
                message={`Are you sure you want to complete this ${
                    sessionType === 'Assignment' ? 'assignment' : 'lesson'
                }?`}
            />

            <ReusableModal
                open={submitLabResponsePromptModalOpen}
                onOk={() => {
                    handleCloseSubmitLabResponseModal();
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => {
                    handleCloseSubmitLabResponseModal();
                }}
                message={`Please submit ${
                    sessionType === 'Assignment' ? 'an assignment' : 'a lab'
                } response before completing this ${sessionType === 'Assignment' ? 'assignment' : 'lesson'}.`}
            />

            <ReusableModal
                open={forceSubmitPromptModalOpen}
                confirmLoading={completeLessonIsLoading}
                closeIcon={null}
                maskClosable={false}
                onOk={() => {
                    handleTimerExpires();
                }}
                cancelText="Go back to pathways"
                okText="Force complete"
                onCancel={() => {
                    navigate(`/`);
                    setSubmitLabResponsePromptModalOpen(false);
                }}
                message={
                    'Oops! Sorry, looks like you ran out of time. Please coordinate with the coaches if you need further guidance.'
                }
            />
        </>
    );
};

export default LabResponseModal;
