import { Modal } from 'antd';
import React from 'react';

const ReusableModal = ({
    open,
    onOk,
    onCancel,
    message,
    cancelButtonProps,
    maskClosable,
    closeIcon,
    cancelText,
    okText,
    confirmLoading
}) => {
    return (
        <Modal
            centered
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            cancelButtonProps={cancelButtonProps}
            maskClosable={maskClosable}
            closeIcon={closeIcon}
            cancelText={cancelText}
            okText={okText}
            confirmLoading={confirmLoading}>
            <div style={{ padding: '8px' }}>
                <h3>{message}</h3>
            </div>
        </Modal>
    );
};

export default ReusableModal;
