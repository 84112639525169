import { Amplify, API } from 'aws-amplify';
import updateAmplifyConfigure from '../utils/CustomAmplifyConfigure';

const SBN_ENDPOINT =
    process.env.SBN_ENDPOINT || `https://ebbano5gx5e33eut4cwlwq2ogy.appsync-api.ap-southeast-1.amazonaws.com/graphql`;

export const GetQuizAPI = async (quizId) => {
    Amplify.configure(updateAmplifyConfigure(SBN_ENDPOINT));

    const GetQuizQuery = `
        query GetQuiz($id: ID!) {
            quiz(id: $id) {
                id
                title
                description
                durationMins
                triesCount
                questions {
                    questionCode
                    questionMd
                    responseType
                    choices {
                        questionChoiceMd
                    }
                }
            }
        }
    `;

    const result = await API.graphql({
        query: GetQuizQuery,
        variables: { id: quizId }
    });

    Amplify.configure(updateAmplifyConfigure());
    return result.data;
};

export const GetAssignmentAPI = async (assignmentId) => {
    Amplify.configure(updateAmplifyConfigure(SBN_ENDPOINT));

    const GetAssignmentQuery = `
    query GetAssignmentQuery($id: ID!) {
        assignment(id: $id) {
          id
          title
          contentMd
          description
        }
      }

    `;
    const result = await API.graphql({
        query: GetAssignmentQuery,
        variables: { id: assignmentId }
    });

    Amplify.configure(updateAmplifyConfigure());
    return result.data;
};

export const GetCourseModules = async (courseId) => {
    Amplify.configure(updateAmplifyConfigure(SBN_ENDPOINT));

    const GetCourseModuleQuery = `
        query cycle($id: ID!) {
            cycle(id: $id) {
                id
                title
                description
                sessions {
                    id
                    title
                    description
                    sessionItems {
                        ... on Lab {
                            __typename
                            id
                            title
                            contentType
                            videoContent
                            urlContent
                            exerFileContent
                            description
                            createdAt
                        }
                        ...on Quiz {
                            __typename
                            id
                            title
                            description
                            createdAt
                        }
                        ...on Assignment {
                            __typename
                            id
                            title
                            description
                            createdAt
                        }
                    }
                }
            }
        }
    `;

    const result = await API.graphql({
        query: GetCourseModuleQuery,
        variables: { id: courseId }
    });

    Amplify.configure(updateAmplifyConfigure());
    return result.data;
};

export const GetLesson = async (labId) => {
    Amplify.configure(updateAmplifyConfigure(SBN_ENDPOINT));

    const GetCourseModuleQuery = `
        query lab($id: ID!) {
            lab(id: $id) {
                id
                title
                description
                bodyMd
                contentType
                videoContent
                urlContent
                exerFileContent
                durationMins
            }
        }
    `;

    const result = await API.graphql({
        query: GetCourseModuleQuery,
        variables: { id: labId }
    });

    Amplify.configure(updateAmplifyConfigure());
    return result.data;
};
