import awsConfigProd from "../aws-exports";
import awsConfigStaging from "../aws-exports-staging";

let awsConfig = awsConfigProd;

if (process.env.REACT_APP_ENVIRONMENT === "staging") {
   // Here I explicitly set the aws-exports configuration to use staging configs
   // to point the production cognito & appsync app configuration on staging environment.

   // This will ensure that when deploying this react app to staging env it
   // it will use the proper cognito & appsync (e.g. see the value of productionRedirectSignIn
   // if process.env.REACT_APP_ENVIRONMENT is not set).
   awsConfig = awsConfigStaging;
}


export default function CustomAmplifyConfigure(customEndpoint) {
   const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
   );

   // Assuming you have two redirect URIs, and the first is for localhost and second is for production
   const [
      localRedirectSignIn,
      productionRedirectSignIn,
   ] = awsConfig.oauth.redirectSignIn.split(",");
   
   const [
      localRedirectSignOut,
      productionRedirectSignOut,
   ] = awsConfig.oauth.redirectSignOut.split(",");
   
   const updatedAwsConfig = {
      ...awsConfig,
      oauth: {
         ...awsConfig.oauth,
         redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
         redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
      }
   }

   if (customEndpoint) {
      updatedAwsConfig.aws_appsync_graphqlEndpoint = customEndpoint;
   }
   return updatedAwsConfig;
}