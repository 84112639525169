import React, { useEffect, useState } from 'react';
import { isJson } from '../../../../utils/isJson';
import { Col, Row, Grid, Button } from 'antd';

const LabResponseTab = ({
    currentLesson,
    holdTextAreaValue,
    hasTimerExpired,
    isSubmitting,
    handleLabResponseSubmit,
    sessionType,
    assignmentInfo
}) => {
    const [showLabResponseError, setShowLabResponseError] = useState(false);
    const [textareaValue, setTextareaValue] = useState('');

    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    useEffect(() => {
        setTextareaValue(holdTextAreaValue);
        if (holdTextAreaValue !== undefined && holdTextAreaValue !== '') {
            if (!isJson(holdTextAreaValue)) {
                setShowLabResponseError(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTextareaChange = (event) => {
        const value = event.target.value;
        setTextareaValue(value);

        if (isJson(value)) {
            setShowLabResponseError(false);
        } else {
            setShowLabResponseError(true);
        }
    };

    const isSubmitDisabled = textareaValue === '' || showLabResponseError === true || !isJson(textareaValue);

    return (
        <Row
            className={`${
                xs ? 'w-full' : 'w-[40vw]'
            } mx-auto my-20 h-auto rounded-md border border-solid border-slate-200`}>
            {sessionType === 'Lab' && (
                <Col className="w-full px-9 py-12 text-center">
                    <h4 className="mb-5 text-lg font-semibold text-primary">Lab Response</h4>

                    {hasTimerExpired && (
                        <h4 className="text-gray-600">
                            You ran out of time to submit a response. Please coordinate with the coaches if you need
                            further guidance.
                        </h4>
                    )}

                    {!hasTimerExpired && (
                        <>
                            {/* LAB RESPONSE DISPLAY FOR MARKDOWN LABS */}
                            {(currentLesson?.contentType === 'Markdown' || currentLesson?.contentType === null) &&
                                currentLesson?.submissions && (
                                    <>
                                        {currentLesson?.submissions !== '{}' && (
                                            <h4 className="text-gray-600">
                                                You have successfully submitted a response. Please coordinate with the
                                                coaches if you need further guidance.
                                            </h4>
                                        )}
                                        {currentLesson?.submissions === '{}' && (
                                            <h4 className="text-gray-600">
                                                You ran out of time to submit a response. Please coordinate with the
                                                coaches if you need further guidance.
                                            </h4>
                                        )}
                                    </>
                                )}

                            {/* LAB RESPONSE DISPLAY FOR VIDEO AND URL LABS */}
                            {currentLesson?.contentType !== null &&
                                currentLesson?.contentType !== 'Markdown' &&
                                currentLesson?.submissions && (
                                    <>
                                        {currentLesson?.submissions !== '{}' && (
                                            <h4 className="text-gray-600">
                                                You have successfully submitted a response. Please coordinate with the
                                                coaches if you need further guidance.
                                            </h4>
                                        )}
                                        {currentLesson?.submissions === '{}' && (
                                            <h4 className="text-gray-600">
                                                You're done with this lesson. If you need more help, talk to the
                                                coaches. Remember, you can only submit lab responses at the specified
                                                time.
                                            </h4>
                                        )}
                                    </>
                                )}

                            {/* LAB DISPLAY WHEN LESSON IS NOT YET COMPLETE */}
                            {!currentLesson?.submissions && (
                                <>
                                    <textarea
                                        className="labResponseTextArea h-[100px] w-full border border-gray-500 p-2"
                                        value={textareaValue}
                                        onChange={handleTextareaChange}></textarea>
                                    {textareaValue !== '' && showLabResponseError && (
                                        <p className="text-left text-red-500">Lab response must be in JSON format</p>
                                    )}

                                    <Button
                                        className={`${isSubmitDisabled ? '' : 'bg-primary'} mt-10`}
                                        size="large"
                                        type="primary"
                                        loading={isSubmitting}
                                        disabled={isSubmitDisabled}
                                        onClick={() => handleLabResponseSubmit({ textareaValue })}>
                                        Submit Response
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Col>
            )}
            {sessionType === 'Assignment' && (
                <>
                    <Col className="w-full px-9 py-12 text-center">
                        <h4 className="mb-5 text-lg font-semibold text-primary">Assignment Response</h4>

                        {!assignmentInfo?.submissions ? (
                            <>
                                <textarea
                                    className="labResponseTextArea h-[100px] w-full border border-gray-500 p-2"
                                    value={textareaValue}
                                    onChange={handleTextareaChange}></textarea>
                                {textareaValue !== '' && showLabResponseError && (
                                    <p className="text-left text-red-500">Lab response must be in JSON format</p>
                                )}

                                <Button
                                    className={`${isSubmitDisabled ? '' : 'bg-primary'} mt-10`}
                                    size="large"
                                    type="primary"
                                    loading={isSubmitting}
                                    disabled={isSubmitDisabled}
                                    onClick={() => handleLabResponseSubmit({ textareaValue })}>
                                    Submit Response
                                </Button>
                            </>
                        ) : (
                            <>
                                <h4 className="text-gray-600">
                                    You have successfully submitted a response. Please coordinate with the coaches if
                                    you need further guidance.
                                </h4>
                            </>
                        )}
                    </Col>
                </>
            )}
        </Row>
    );
};

export default LabResponseTab;
