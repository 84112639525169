import React, { createContext, useReducer } from 'react';

const initialState = { assessment: null, quiz: null, loading: false };
export const AssessQuizProviderContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ASSESSMENT':
            return { ...state, assessment: action.payload };
        case 'SET_QUIZ':
            return { ...state, quiz: action.payload };
        case 'LOADING':
            return { ...state, loading: true };
        case 'LOADING_FINISH':
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const AssessQuizProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AssessQuizProviderContext.Provider value={{ assessQuizState: state, assessQuizDispatch: dispatch }}>
            {children}
        </AssessQuizProviderContext.Provider>
    );
};
