import React, { useState, useEffect } from 'react';
import { Col, Button, Tooltip, Typography, Radio, Checkbox, Collapse, Select, Row } from 'antd';
import { GetQuizAPI } from '../../../vendor/SpringboardAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuizSection from '../../assessment/QuizSection';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { getUserQuiz, createUserQuiz, startUserQuiz, submitUserQuiz } from '../../../data/shared';
import './../../../assets/css/quiz.css';

const { Text } = Typography;
const { Option } = Select;

const QuizContent = ({ courseId }) => {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [quiz, setQuiz] = useState(null);
    const [displayedIndex, setDisplayedIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(quiz?.questions?.length).fill([]));

    const [timeStarted, setTimeStarted] = useState(null);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [timerExpired, setTimerExpired] = useState(false);

    const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
    const [quizAttempts, setQuizAttempts] = useState([]);
    const [currentQuizAttempts, setCurrentQuizAttempts] = useState(null);
    const [isInsert, setIsInsert] = useState(false);
    const [isStarting, setIsStarting] = useState(false);
    const [attemptsLeft, setAttemptsLeft] = useState(5);

    const [questions, setQuestions] = useState(null);
    const [filteredQuestions, setFilteredQuestions] = useState(null);

    const { contentId: quizId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (currentQuizAttempts) {
            const timestamp = parseInt(!currentQuizAttempts?.timeStarted ? 0 : currentQuizAttempts?.timeStarted);
            setTimeStarted(timestamp);
            setIsStarting(false);
        }
    }, [currentQuizAttempts]);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);

            const getQuizResult = await GetQuizAPI(quizId);
            let _quiz = getQuizResult.quiz;
            setQuiz(_quiz);
            setQuestions(null);

            const getUserQuizResult = await getUserQuiz(courseId, quizId);
            setIsInsert(
                (() => {
                    return !getUserQuizResult ? true : false;
                })()
            );

            setQuizAttempts(getUserQuizResult?.attempts);
            setCurrentQuizAttempts(getUserQuizResult?.mostRecentAttempt);
            setMaxAttemptsReached(_quiz?.triesCount <= getUserQuizResult?.attempts.length);

            setLoading(false);
        };
        setQuizCompleted(false);
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (quizAttempts) {
            setAttemptsLeft(parseInt(quiz?.triesCount) - parseInt(quizAttempts?.length));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizAttempts]);

    const DisplayQuestionExplanation = (q, index) => {
        return (
            <Col className="mb-12" key={index}>
                <Text className="mb-2 block text-lg font-bold">{indexLabel(q.questionCode)}. Question</Text>
                <Text className="mb-4 block text-lg">{q.questionMd}</Text>
                {q.choices.map((choice, qIndex) => (
                    <Col key={`choice-${qIndex}`}>
                        {q.responseType === 'SINGLE' && (
                            //Antdesign radio button is buggy when questions are filtered T___T
                            <Row
                                className={`${handleChoicesColor(choice.questionChoiceMd, choice.correct, q.questionCode)} mb-2 w-full items-center rounded-md border border-solid px-4 py-2 text-lg text-black`}>
                                <div class="flex items-center gap-4">
                                    <div class="flex h-4 w-4 items-center justify-center rounded-full border border-gray-400 bg-white">
                                        {choicesDefaultValue(q.questionCode, true) === choice.questionChoiceMd && (
                                            <div class="h-2 w-2 rounded-full bg-gray-300"></div>
                                        )}
                                    </div>
                                    <p>{choice.questionChoiceMd}</p>
                                </div>
                            </Row>
                        )}
                        {q.responseType === 'MULTIPLE' && (
                            <Row
                                className={`${handleChoicesColor(choice.questionChoiceMd, choice.correct, q.questionCode)} mb-2  w-full items-center  rounded-md border border-solid px-4 py-2 text-lg text-black`}>
                                <div class="flex items-center gap-4">
                                    <div class="flex h-4 w-4 items-center  justify-center border border-gray-400 bg-white">
                                        {choicesDefaultValue(q.questionCode, false)?.includes(
                                            choice.questionChoiceMd
                                        ) && (
                                            <span role="img" aria-label="checkmark" className="font-bold text-gray-300">
                                                &#x2713;
                                            </span>
                                        )}
                                    </div>
                                    <p>{choice.questionChoiceMd}</p>
                                </div>
                            </Row>
                        )}
                    </Col>
                ))}
                <Col className="mt-4 rounded-md border border-solid bg-slate-100 px-8 py-4">
                    <p className="text-lg font-bold">{q.isCorrect ? 'Correct' : 'Incorrect'}</p>
                    <div>{formatChoices(q.correctAnswerMd) || null}</div>
                </Col>
            </Col>
        );
    };

    // HANDLER WHEN TIMER ENDS
    const handleTimeCompleted = () => {
        const answers = localStorage.getItem(`Quiz-${quiz.id}`);
        setTimerExpired(true);
        submitQuiz(JSON.parse(answers) || []);
    };

    // HANDLER WHEN QUIZ IS LAST ITEM
    const isLastIndex = () => {
        if (!quiz) return;
        const isLast = displayedIndex === quiz.questions?.length - 1;
        return isLast;
    };

    // HANDLER WHEN USER SUBMIT QUIZ AND END EXAM
    const submitQuiz = (answers) => {
        setBtnLoading(true);
        const fetchApi = async () => {
            const submitUserQuizResult = await submitUserQuiz({
                quizId: quiz.id,
                courseId,
                title: quiz.title,
                answers: JSON.stringify(answers)
            });

            const { attempts } = submitUserQuizResult.quiz;
            setQuestions(submitUserQuizResult.questions);
            setFilteredQuestions(submitUserQuizResult.questions);

            setQuizAttempts(attempts);
            setMaxAttemptsReached(quiz?.triesCount === attempts?.length);
            setCurrentQuizAttempts(null); //Set updated currentQuizAttempt to change UI

            localStorage.removeItem(`Quiz-${quiz.id}`);
            setBtnLoading(false);
        };

        fetchApi();

        setQuizCompleted(true);
    };

    const navigateQuestionItem = (index) => {
        setDisplayedIndex(index);
    };

    const cancel = (event) => {
        return null;
    };

    const quizComputeDeadline = () => {
        let startTime = timeStarted || Date.now();
        let quizDuration = quiz?.durationMins * 60000;

        const nowDiff = Date.now() - startTime;
        const remaining = quizDuration - nowDiff;

        if (remaining <= 0) {
            return 0;
        }

        return Date.now() + remaining;
    };

    const handleStart = async (isRetake = false) => {
        setIsStarting(true);

        let requestpayload = {
            quizId: quizId,
            courseId: courseId,
            title: quiz.title
        };

        if (isInsert && !isRetake) {
            await createUserQuiz(requestpayload);
        }

        const startedQuizResult = await startUserQuiz(requestpayload);
        const { attempts, mostRecentAttempt } = startedQuizResult;

        setAnswers(Array(quiz?.questions?.length).fill([])); // Always reset answers every start quiz
        setDisplayedIndex(0); // Always reset display index = 0 every start quiz
        setQuizAttempts(attempts);
        setCurrentQuizAttempts(mostRecentAttempt);
        setMaxAttemptsReached(quiz.triesCount === attempts?.length);
    };

    const convertTimestampToReadableDate = (timestamp) => {
        const date = new Date(+timestamp);
        return date.toLocaleString(); // Adjust the format as needed
    };

    const handleChoicesColor = (choice, correct, questionCode) => {
        const index = questions?.findIndex((q) => q.questionCode === questionCode);
        const answerCorrect = answers[index]?.answer?.includes(choice) && correct;
        const answerIncorrect = answers[index]?.answer?.includes(choice) && !correct;
        const unselectedCorrect = !answers[index]?.answer?.includes(choice) && correct;

        let bgColor = '';
        if (answerCorrect) {
            bgColor = 'bg-green-200 ';
        }

        if (answerIncorrect) {
            bgColor = 'border-red-400 border-solid';
        }

        if (unselectedCorrect) {
            bgColor = 'bg-green-200';
        }

        return bgColor;
    };

    const indexLabel = (questionCode) => {
        const index = questions?.findIndex((q) => q.questionCode === questionCode);
        if (index !== -1) {
            return index + 1;
        } else {
            return 0;
        }
    };

    const choicesDefaultValue = (questionCode, isRadio = true) => {
        let current = answers.filter((ans) => ans.questionCode === questionCode)[0];
        if (isRadio) {
            return current?.answer[0];
        } else {
            return current?.answer;
        }
    };

    const handleFilterChange = (value) => {
        if (value === 'correct') {
            setFilteredQuestions(questions?.filter((q) => q.isCorrect));
        }

        if (value === 'incorrect') {
            setFilteredQuestions(questions?.filter((q) => !q.isCorrect));
        }

        if (value === '') {
            setFilteredQuestions(questions);
        }
    };

    function formatChoices(choicesMd) {
        const formatted = choicesMd.split('\n\n').map((paragraph, index) => (
            <p key={index} className="mb-4 text-lg">
                {paragraph}
            </p>
        ));
        return formatted;
    }

    return !loading ? (
        <div className="quiz">
            {!currentQuizAttempts && (
                <div>
                    <h1 className="mb-5 text-5xl font-semibold">{quiz?.title}</h1>
                    <div className="mb-5 min-h-[360px]">
                        {!quizCompleted ? (
                            <div>
                                <h3 className="text-2xl font-bold">
                                    You are given {quiz.durationMins} minutes to complete this quiz.
                                </h3>

                                <p className="mt-4 max-w-[900px] text-lg">
                                    <span className="font-bold text-red-500">Note: </span>
                                    You are not allowed to leave the page or refresh the browser while the quiz is
                                    ongoing. Please ensure you have completed the quiz before leaving the page.
                                </p>

                                {quizAttempts && (
                                    <div>
                                        <p className="mt-12 text-lg">
                                            <span className="font-bold">Quiz Attempts Left: </span>
                                            {attemptsLeft}
                                        </p>
                                        <p className="text-lg">
                                            <span className="font-bold">Your Recent attempts: </span>
                                        </p>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quizAttempts?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {convertTimestampToReadableDate(item.timeCompleted)}
                                                            </td>
                                                            <td>
                                                                {item.totalScore} / {item.totalItems}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {!timerExpired && <h3 className="mt-12 text-2xl">Good job on completing this quiz!</h3>}

                                {timerExpired && <h3 className="mt-12 text-2xl">Looks like you ran out of time...</h3>}

                                <p className="rounded-md bg-gray-100 p-2 text-xl">
                                    You got{' '}
                                    <span className="font-bold">
                                        {quizAttempts[quizAttempts?.length - 1].totalScore} out of{' '}
                                        {quizAttempts[quizAttempts?.length - 1].totalItems}{' '}
                                    </span>{' '}
                                    items correct.
                                </p>
                            </div>
                        )}
                        <div className={`mt-5 flex gap-2`}>
                            <Button
                                style={{}}
                                size="large"
                                onClick={() => {
                                    navigate(`/`);
                                }}>
                                Go back to home
                            </Button>
                            <Tooltip
                                title={
                                    maxAttemptsReached
                                        ? 'You have reached the maximum number of allowed attempts.'
                                        : null
                                }>
                                <>
                                    {!quizAttempts || quizAttempts.length === 0 ? (
                                        <Button
                                            className={`${!maxAttemptsReached ? 'bg-primary text-white' : ''}`}
                                            loading={isStarting}
                                            disabled={maxAttemptsReached}
                                            size="large"
                                            onClick={() => {
                                                handleStart();
                                            }}>
                                            Start Quiz
                                        </Button>
                                    ) : (
                                        <Button
                                            className={`${!maxAttemptsReached ? 'bg-primary text-white' : ''}`}
                                            loading={isStarting}
                                            disabled={maxAttemptsReached}
                                            size="large"
                                            onClick={() => {
                                                handleStart(true);
                                            }}>
                                            Retake Quiz
                                        </Button>
                                    )}
                                </>
                            </Tooltip>
                        </div>
                        {questions && questions.length !== 0 && (
                            <Collapse
                                className="mt-8"
                                size="large"
                                items={[
                                    {
                                        key: '1',
                                        label: 'Toggle to review quiz results',
                                        children: (
                                            <>
                                                <Select
                                                    defaultValue={''}
                                                    className="h-[42px] min-w-[400px]"
                                                    onChange={handleFilterChange}
                                                    placeholder="Filter questions">
                                                    <Option value={''}>View all questions</Option>
                                                    <Option value={'correct'}>View correct answers</Option>
                                                    <Option value={'incorrect'}>View incorrect answers</Option>
                                                </Select>
                                                <Col className="mt-8">
                                                    {filteredQuestions?.map((q, index) =>
                                                        DisplayQuestionExplanation(q, index)
                                                    )}
                                                </Col>
                                            </>
                                        )
                                    }
                                ]}></Collapse>
                        )}
                    </div>
                </div>
            )}
            {currentQuizAttempts && (
                <Col style={{ width: '100%' }}>
                    <QuizSection
                        quiz={quiz}
                        handleTimeCompleted={handleTimeCompleted}
                        displayedIndex={displayedIndex}
                        loading={loading}
                        navigateQuestionItem={navigateQuestionItem}
                        answers={answers}
                        isLastIndex={isLastIndex}
                        cancel={cancel}
                        btnLoading={btnLoading}
                        setAnswers={setAnswers}
                        submitQuiz={submitQuiz}
                        quizComputeDeadline={quizComputeDeadline()}
                    />
                </Col>
            )}
        </div>
    ) : (
        <CourseLoader />
    );
};

export default QuizContent;
