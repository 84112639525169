import { Row } from 'antd';
import React from 'react';
import '../../../assets/css/loader.css';

const CourseStatusLoader = () => {
    const loaderCount = [1, 2];

    return (
        <>
            <div className="custom-loader h-[36px] w-[120px]"></div>
            {loaderCount.map((i, index) => {
                return <Row key={index} className="custom-loader mb-4 h-[120px] w-full "></Row>;
            })}
        </>
    );
};

export default CourseStatusLoader;
