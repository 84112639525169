import { Col, Row, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PeopleManagerProviderContext } from '../../../providers/PeopleManagerProvider';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../../../utils/ApiGwOptions';
import SaaLogo from '../../../assets/aws-saa-logo.png';
import Chart from 'react-apexcharts';
import CourseLoader from '../../layout/loaders/CourseLoader';

const { Text, Title } = Typography;

const Dashboard = () => {
    const { peopleMgrState, peopleMgrDispatch } = useContext(PeopleManagerProviderContext);
    const { currentAuthenticatedUser } = peopleMgrState;
    const { courses, saaResults } = peopleMgrState;
    const [numberOfLabs, setNumberOfLabs] = useState(0);
    const [numberOfKnowledgeChecks, setNumberOfKnowledgeChecks] = useState(0);

    useEffect(() => {
        if (token && courses?.length === 0) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getAllCoursesStats`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    data.coursesStats = data.coursesStats?.sort((a, b) => {
                        const codeA = a.courseCode;
                        const codeB = b.courseCode;
                        return codeA.localeCompare(codeB);
                    });

                    peopleMgrDispatch({
                        type: 'SAVE_COURSES',
                        payload: data.coursesStats
                    });
                }
            };

            getLearnersQuizzesAndExams();
        }

        if (token && saaResults === null) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getSaaExamResults`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    const order = ['passed', 'pending', 'failed'];
                    const counts = data?.saaExamResults;
                    const orderedCounts = order.map((key) => +counts[key]);
                    peopleMgrDispatch({
                        type: 'SAVE_SAA_RESULTS',
                        payload: orderedCounts
                    });
                }
            };

            getLearnersQuizzesAndExams();
        }
    }, []);

    useEffect(() => {
        let labs = 0;
        let quizzes = 0;
        let exams = 0;
        if (courses?.length !== 0) {
            courses.forEach((course) => {
                labs = labs + +course.labs;
                quizzes = quizzes + +course.quizzes;
                exams = exams + +course.exams;
            });

            setNumberOfLabs(labs);
            setNumberOfKnowledgeChecks(quizzes + exams);
        }
    }, [courses]);

    const options = {
        labels: ['Passed', 'Pending', 'Failed'],
        colors: ['#088B06', '#FFAD47', '#A93B3B'],
        legend: {
            position: 'bottom'
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '40%'
                }
            }
        }
    };

    const loading = numberOfKnowledgeChecks === 0 && numberOfLabs === 0 && courses?.length === 0;

    return (
        <>
            {loading ? (
                <CourseLoader isLesson={false} />
            ) : (
                <Col>
                    <Text className="text-lg">Hello {currentAuthenticatedUser?.name}, welcome back! 👋</Text>
                    <Title style={{ marginTop: '8px' }}>Today's Updates</Title>

                    <Row className="mt-8 justify-between ">
                        <Col className="w-1/2 pr-4">
                            <Text className="text-lg">Technical Development</Text>
                            <Col className="h-[300px] rounded-lg border-2 border-solid border-gray-300 p-10">
                                <Row className="mb-8 items-center justify-between">
                                    <Text className="w-[40%] text-5xl font-bold">20</Text>
                                    <Text className="w-[60%] text-lg">Number of technical sessions delivered</Text>
                                </Row>
                                <Row className="mb-8 items-center justify-between">
                                    <Text className="w-[40%] text-5xl font-bold">{numberOfKnowledgeChecks}</Text>
                                    <Text className="w-[60%] text-lg">Number of knowledge checks</Text>
                                </Row>
                                <Row className="items-center justify-between">
                                    <Text className="w-[40%] text-5xl font-bold">{numberOfLabs}</Text>
                                    <Text className="w-[60%] text-lg">Number of technical labs</Text>
                                </Row>
                            </Col>
                        </Col>
                        <Col className="w-1/2 ">
                            <Text className=" text-lg">Certifications</Text>
                            <Col className="h-[300px] rounded-lg border-2 border-solid border-gray-300">
                                <Row className="h-full items-center p-10">
                                    <Col className=" w-[40%]">
                                        <img src={SaaLogo} alt="Logo" className="max-h-[200px]" />
                                    </Col>
                                    <Col className="w-[60%]">
                                        <Chart options={options} series={saaResults} type="donut" width={300} />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            )}
        </>
    );
};

export default Dashboard;
