import React, { useEffect, useState } from 'react';
import { Layout, Typography, Progress, Row, Col, Card, Grid } from 'antd';
import Background from '../components/layout/Background';
import MainHeader from '../components/layout/Header';
import { Content } from 'antd/es/layout/layout';
import '../assets/css/pathway.css';
import Flag from '../assets/flag.png';
import { LockOutlined } from '@ant-design/icons';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCoursesByUserPathways } from '../data/shared';
import PathwayLoader from '../components/pathway/PathwayLoader';

const Pathway = () => {
    const [courses, setCourses] = useState([]);
    const [totalPercent, setTotalPercent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs, lg } = useBreakpoint();
    const { slug } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const title = searchParams.get('t') || localStorage.getItem('pathway-title');

    const computeTotalPercentage = (completed, total) => {
        const percentage = (completed / total) * 100;
        return Math.floor(percentage); // Round down
    };

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            let _courses = await getCoursesByUserPathways(slug);

            const completed = _courses.filter((item) => parseFloat(item.percent) === 100).length;

            setTotalPercent(computeTotalPercentage(completed, _courses.length));

            const isEnabled = (percent, deps) => {
                // NOTE !! - To be refactored on future releases!
                // temporarily added a condition here to manually identify whether a course is enabled or disabled based on its dependencies
                // Enabling courses shall be computed on the backend
                if (deps.length !== 0) {
                    let depsCompleted = true;

                    for (let i = 0; i < deps.length; i++) {
                        if (_courses.filter((item) => item.id === deps[i])[0].percent !== '100') {
                            depsCompleted = false;
                        }
                    }

                    return depsCompleted;
                }

                if (deps.length === 0 || percent === 100) {
                    return true;
                }
            };

            setCourses(
                _courses.map((crs) => {
                    // Check and set if course is disabled
                    // NOTE!!!
                    // On backend compute deps, remove ids in array of deps[] when course is complete.
                    // Create a complete button functionality
                    return {
                        ...crs,
                        disabled: !isEnabled(parseFloat(crs.percent), crs.deps)
                    };
                })
            );

            setIsLoading(false);
        };

        fetch();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout className="relative h-screen">
            <Background />
            <MainHeader hideWelcome={true} />
            <Content className="relative min-h-screen">
                <Row className="pathway-bg">
                    <div className={`mx-auto w-full max-w-[1920px]  ${xs ? 'p-8' : 'p-16'}`}>
                        <Col className="w-full ">
                            <h4 level={4} className="text-xl font-semibold text-white">
                                Pathway:
                            </h4>
                            <h2 className="mb-10 text-3xl font-semibold text-white">{title} Specialization Program</h2>
                            <p className="text-white">Your current progress:</p>
                            <div className="flex items-center ">
                                <Progress
                                    strokeLinecap="butt"
                                    trailColor="white"
                                    showInfo={false}
                                    percent={totalPercent}
                                    className="progress-text m-0 p-0 pr-4"
                                />
                                <p className="text-right font-bold text-white">{totalPercent}%</p>
                            </div>
                        </Col>

                        {isLoading ? (
                            <div className="mt-14">
                                <PathwayLoader />
                            </div>
                        ) : (
                            <Row gutter={[24, 24]} className="mt-14 items-stretch">
                                {courses.map((item, index) => {
                                    return (
                                        <Col
                                            key={index}
                                            xs={24}
                                            sm={12}
                                            md={8}
                                            lg={6}
                                            xl={6}
                                            className={`
                                                ${xs ? 'max-w-full' : 'max-w-[360px]'}
                                                ${item.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                            onClick={() => {
                                                if (!item.disabled) {
                                                    navigate(`/course/${item.id}`);
                                                }
                                            }}>
                                            <Card
                                                className="w-full rounded-none border-none bg-transparent"
                                                bodyStyle={{ padding: 0 }}>
                                                <div className="relative flex items-center justify-center bg-black">
                                                    <img
                                                        alt="course-cover"
                                                        src={item.coverPhotoUrl}
                                                        className={`h-full w-full ${
                                                            item.disabled
                                                                ? 'pointer-events-none opacity-10 grayscale'
                                                                : 'pointer-events-auto'
                                                        }`}
                                                    />
                                                    {item.disabled && (
                                                        <LockOutlined className="absolute text-[60px] text-white" />
                                                    )}
                                                </div>
                                                <Row
                                                    className={`flex flex-col justify-between bg-white p-3
                                                        ${lg ? 'h-[120px]' : xs ? 'h-[110px]' : 'h-[140px]'}`}>
                                                    <Text className="pr-4 font-semibold">{item.title}</Text>
                                                    <Row className="mt-4 justify-between">
                                                        <Text>{item.courseCode}</Text>
                                                        <Row>
                                                            <img alt="course-percentage" src={Flag} className="mr-1" />
                                                            <Text className="font-semibold">
                                                                {item.disabled ? '0%' : `${item.percent}%`}
                                                            </Text>
                                                        </Row>
                                                    </Row>
                                                </Row>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                    </div>
                </Row>
            </Content>
        </Layout>
    );
};

export default Pathway;
