import { Layout, Typography } from 'antd';
import Background from '../Background';
import WhiteLogo from '../../../assets/logo-white.png';
const { Content } = Layout;
const { Text } = Typography;

const PageLoader = ({ message }) => {
    if (message) {
        return <div>{message}</div>;
    }
    return (
        <div className="absolute top-0 z-50">
            <div className="relative">
                <Background />
                <Content className="relative flex min-h-screen w-screen flex-col items-center justify-center">
                    <img src={WhiteLogo} alt={'logo'} className="h-[100px]" />
                    <Text className="mt-4 text-lg text-white">App is loading...</Text>
                </Content>
            </div>
        </div>
    );
};

export default PageLoader;
