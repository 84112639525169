import React from 'react';
import '../../assets/css/home.css';

const Background = () => {
    return (
        <div>
            <div className="fixed-bg"></div>
            <div className="grain-texture"></div>
            <div className="globe-vector"></div>
            <div className="dark-gradient"></div>
            <div className="bg-circle"></div>
            <div className="yellow-gradient"></div>
        </div>
    );
};

export default Background;
