import React, { useState } from 'react';
import { Button } from 'antd';
import { startUserLesson, createUserLesson } from '../../../data/shared';

const VideoContent = ({ currentLesson, setCurrentLesson, lessonStartTime, setLessonStartTime }) => {
    const [loading, setLoading] = useState(false);

    const startLesson = async () => {
        setLoading(true);

        if (currentLesson.insert) {
            await createUserLesson({
                lessonId: currentLesson.id,
                courseId: currentLesson.courseId,
                title: currentLesson.title
            });
        }

        const data = {
            courseId: currentLesson.courseId,
            lessonToStartId: currentLesson.id
        };

        const newLessonData = await startUserLesson(data);

        const timestamp = parseInt(!newLessonData.timeStarted ? 0 : newLessonData.timeStarted);

        setCurrentLesson({
            ...currentLesson,
            status: newLessonData.status,
            timeStarted: newLessonData.timeStarted
        });

        setLessonStartTime(() => Date.now(timestamp));
        setLoading(false);
    };

    return (
        <>
            <h1 className="mb-5 text-5xl font-semibold">{currentLesson.title}</h1>
            {currentLesson.status === 'NOT_STARTED' && (
                <div className={`mb-5 flex h-[300px] gap-2 `}>
                    <Button style={{}} size="large">
                        Go back to previous lesson
                    </Button>
                    <Button
                        className="bg-primary text-white"
                        size="large"
                        loading={loading}
                        onClick={() => {
                            startLesson();
                        }}>
                        Start lesson
                    </Button>
                </div>
            )}
            {currentLesson.status !== 'NOT_STARTED' && (
                <iframe
                    src={`https://muse.ai/embed/${currentLesson.videoContent}?search=0&logo=0`}
                    width="100%"
                    height="100%"
                    title={currentLesson.title}
                    allowFullScreen></iframe>
            )}
        </>
    );
};

export default VideoContent;
