import Sider from 'antd/es/layout/Sider';
import React from 'react';
import MenuList from './MenuList';

const DesktopMenu = ({ modules, menuItems, isMenuOpen, setIsMenuOpen, contentId, xs }) => {
    return (
        <>
            {!xs && (
                <Sider width={250}>
                    <div className="z-10 h-full overflow-y-auto border-r-2 border-solid border-gray-100 bg-white">
                        {menuItems.length !== 0 && (
                            <MenuList
                                modules={modules}
                                menuItems={menuItems}
                                isMenuOpen={isMenuOpen}
                                setIsMenuOpen={setIsMenuOpen}
                                contentId={contentId}
                            />
                        )}
                    </div>
                </Sider>
            )}
        </>
    );
};

export default DesktopMenu;
