import React, { useRef } from 'react';
import { Menu, Grid } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const MenuList = ({ modules, menuItems, isMenuOpen, setIsMenuOpen, contentId }) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const navigate = useNavigate();
    const scrollIntoViewRef = useRef(null);

    const { slug } = useParams();

    const handleMenuItemClick = (e) => {
        navigate(`/course/${slug}/cc/${e.keyPath[0]}`);

        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });

        if (xs && isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    return (
        <>
            <div className="px-6 py-3 pr-10 font-semibold">{modules?.title}</div>

            <Menu
                onClick={(e) => handleMenuItemClick(e)}
                className="w-auto overflow-y-auto border-b-2 border-solid border-gray-100"
                mode="inline"
                items={menuItems}
                defaultSelectedKeys={[contentId]}
                defaultOpenKeys={[
                    menuItems?.filter((item) => item?.children?.some((child) => child?.key === contentId))[0]?.key
                ]}
            />
        </>
    );
};

export default MenuList;
