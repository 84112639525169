export const token = localStorage.getItem('PeopleMgrToken');

export const API_GATEWAY_ENDPOINT = process.env.REACT_APP_API_GATEWAY_ENDPOINT;

export const apiGatewayOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': token
    }
};
