import { Col, Row, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PeopleManagerProviderContext } from '../../../providers/PeopleManagerProvider';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../../../utils/ApiGwOptions';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { staging as stagingCourses } from '../../../data/PathwayCoursesStaging';
import { prod as prodCourses } from '../../../data/PathwayCoursesProd';

const { Title } = Typography;
const { Option } = Select;

const Courses = () => {
    const { peopleMgrState, peopleMgrDispatch } = useContext(PeopleManagerProviderContext);
    const { courses, saaResults } = peopleMgrState;
    const [courseFilter, setCourseFilter] = useState(
        (process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses).map((item) => item.code)
    );
    const pathwayCoursesData = process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses;

    useEffect(() => {
        if (token && courses.length === 0) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getAllCoursesStats`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    data.coursesStats = data.coursesStats?.sort((a, b) => {
                        const codeA = a.courseCode;
                        const codeB = b.courseCode;
                        return codeA.localeCompare(codeB);
                    });

                    peopleMgrDispatch({
                        type: 'SAVE_COURSES',
                        payload: data.coursesStats
                    });
                }
            };

            getLearnersQuizzesAndExams();
        }

        if (token && saaResults === null) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getSaaExamResults`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    const order = ['passed', 'pending', 'failed'];
                    const counts = data?.saaExamResults;
                    const orderedCounts = order.map((key) => +counts[key]);
                    peopleMgrDispatch({
                        type: 'SAVE_SAA_RESULTS',
                        payload: orderedCounts
                    });
                }
            };

            getLearnersQuizzesAndExams();
        }
    }, []);

    const handleCourseFilter = (selectedCourses) => {
        const allCourses = (process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses).map(
            (item) => item.code
        );
        if (selectedCourses.length !== 0) {
            setCourseFilter(selectedCourses);
        } else {
            setCourseFilter(allCourses);
        }
    };

    const emptyCourse = (item) => {
        if (item.exams === '0' && item.labs === '0' && item.quizzes === '0') {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {courses?.length === 0 ? (
                <CourseLoader isLesson={false} />
            ) : (
                <Col>
                    <Title style={{ marginTop: '0px' }}>Courses</Title>
                    <Row className="mb-8">
                        <Select
                            allowClear
                            placeholder="Filter by Course"
                            onChange={handleCourseFilter}
                            mode="multiple"
                            className="select-overflow w-full min-w-[400px]">
                            {pathwayCoursesData.map((course, index) => {
                                return (
                                    <Option value={course.code} key={index}>
                                        {course.code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Row>

                    <table className="w-full">
                        <thead className="bg-primary text-white">
                            <tr>
                                <th className=" ">Course</th>
                                <th>Course Code</th>
                                <th>Labs</th>
                                <th>Quizzes</th>
                                <th>Exams</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses
                                ?.filter?.((course) => courseFilter.includes(course.courseCode))
                                ?.map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={`${emptyCourse(item) ? 'bg-gray-200 text-gray-600' : ''}`}>
                                            <td>{item.title}</td>
                                            <td>{item.courseCode}</td>
                                            <td>{item.labs}</td>
                                            <td>{item.quizzes}</td>
                                            <td>{item.exams}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </Col>
            )}
        </>
    );
};

export default Courses;
