import { API } from 'aws-amplify';

export const getPathwaysByOrg = async () => {
    const getPathwaysByOrgStatusQuery = `
        query getPathwaysByOrgStatus {
            getPathwaysByOrgStatus {
                id
                orgId
                status
                title
                description
                createdAt
                updatedAt
            }
        }
    `;

    let result = await API.graphql({
        query: getPathwaysByOrgStatusQuery
    });

    return result.data.getPathwaysByOrgStatus;
};

export const getCoursesByUserPathways = async (pwId) => {
    const getCoursesByUserPathwaysQuery = `
        query getCoursesByUserPathway($id: ID!) {
            getCoursesByUserPathway(id: $id) {
                id
                userId
                courseCode
                pathwayId
                title
                description
                coverPhotoUrl
                deps
                percent
                createdAt
                updatedAt
            }
        }
    `;

    let result = await API.graphql({
        query: getCoursesByUserPathwaysQuery,
        variables: { id: pwId }
    });

    return result.data.getCoursesByUserPathway;
};

export const getCurrentLessonByUser = async (courseId, lessnId) => {
    const getCurrentLessonQuery = `
        query getCurrentLesson($cid: ID!, $lessnId: String) {
            getCurrentLesson(cid: $cid, lessnId: $lessnId) {
                id
                userId
                courseId
                title
                timeStarted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: getCurrentLessonQuery,
        variables: { cid: courseId, lessnId }
    });
    return response.data.getCurrentLesson;
};

export const startUserLesson = async (data) => {
    const startUserLessonQuery = `
        mutation startUserLesson($input: StartLessonInput) {
            startUserLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: startUserLessonQuery,
        variables: {
            input: data
        }
    });
    return response.data.startUserLesson;
};

export const completeUserLesson = async (data) => {
    const completeUserLessonQuery = `
        mutation completeUserLesson($input: CompleteLessonInput) {
            completeUserLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                timeCompleted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: completeUserLessonQuery,
        variables: {
            input: data
        }
    });
    return response.data.completeUserLesson;
};

export const getAllCompletedLessons = async (courseId) => {
    const getAllCompletedLessonsQuery = `
        query getAllCompletedLessons($courseId: ID!) {
            getAllCompletedLessons(courseId: $courseId) {
                lessons {
                    id
                    userId
                    courseId
                    title
                }
                count
            }
        }
    `;
    const response = await API.graphql({
        query: getAllCompletedLessonsQuery,
        variables: { courseId }
    });
    return response.data.getAllCompletedLessons;
};

export const submitLabResponse = async (data) => {
    const submitLabResponseQuery = `
        mutation submitLabResponse($input: SubmitLabResponseInput) {
            submitLabResponse(input: $input) {
                id
                userId
                courseId
                title
                submissions
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: submitLabResponseQuery,
        variables: {
            input: data
        }
    });
    return response.data.submitLabResponse;
};

export const createUserLesson = async (data) => {
    const createUserLessonQuery = `
        mutation createLesson($input: CreateLessonInput) {
            createLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: createUserLessonQuery,
        variables: {
            input: data
        }
    });
    return response.data.createLesson;
};

export const startAssessmentQuery = async (id) => {
    const startAssessmentQuery = `
        mutation StartAssessment($input: StartAsessmentInput!) {
            startAssessment(input: $input) {
                id
                userId
                quizId
                orgId
                status
                attempts {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                mostRecentAttempt {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: startAssessmentQuery,
        variables: {
            input: { id }
        }
    });

    return response.data;
};

export const createCoursesByUsers = async (pwId) => {
    const createCoursesByUsersQuery = `
        mutation createCoursesByUsers {
            createCoursesByUsers {
                id
                userId
                pathwayId
                title
                courseCode
                description
                coverPhotoUrl
                deps
                percent
                createdAt
                updatedAt
            }
        }
    `;

    let result = await API.graphql({
        query: createCoursesByUsersQuery
    });

    return result.data.createCoursesByUsersQuery;
};

export const enrollUser = async () => {
    const enrollUserQuery = `
        mutation EnrollUser {
            enrollUser {
                id username orgId name email role enrollmentStatus
            }
        }
    `;

    const response = await API.graphql({ query: enrollUserQuery });
    return response.data.enrollUser;
};

export const getAssessment = async () => {
    const getAssessmentQuery = `
        query GetAssessment {
            getAssessment {
                id
                userId
                quizId
                orgId
                status
                attempts {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                mostRecentAttempt {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: getAssessmentQuery
    });

    return response.data;
};

export const submitAssessmentAnswersQuery = async (id, answers) => {
    const submitAssessmentAnswersQuery = `
        mutation SubmitAssessmentAnswers($input: SubmitAssessmentAnswersInput!) {
            submitAssessmentAnswers(input: $input) {
                id
                userId
                quizId
                orgId
                status
                attempts {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                mostRecentAttempt {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: submitAssessmentAnswersQuery,
        variables: {
            input: {
                id,
                answers: JSON.stringify(answers)
            }
        }
    });

    return response.data;
};

// =====
// QUIZ
// =====
export const getUserQuiz = async (courseId, quizId) => {
    const getCurrentQuizQuery = `
        query getUserQuiz($id: ID!, $courseId: String) {
            getUserQuiz(id: $id, courseId: $courseId) {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: getCurrentQuizQuery,
        variables: { id: quizId, courseId: courseId }
    });
    return response.data.getUserQuiz;
};

export const createUserQuiz = async (data) => {
    const createUserQuizQuery = `
        mutation createUserQuiz($input: CreateUserQuizInput) {
            createUserQuiz(input: $input) {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: createUserQuizQuery,
        variables: {
            input: data
        }
    });
    return response.data.createUserQuiz;
};

export const startUserQuiz = async (data) => {
    const startUserQuizQuery = `
        mutation startUserQuiz($input: StartUserQuizInput) {
            startUserQuiz(input: $input) {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
        }
    `;

    const response = await API.graphql({
        query: startUserQuizQuery,
        variables: {
            input: data
        }
    });
    return response.data.startUserQuiz;
};

export const submitUserQuiz = async (data) => {
    const submitUserQuizQuery = `
    mutation submitUserQuiz($input: SubmitUserQuizInput) {
        submitUserQuiz(input: $input) {
            quiz {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
            questions {
                questionCode
                questionMd
                responseType
                choices {
                    questionChoiceMd
                    correct
                }
                correctAnswerMd
                isCorrect
            }
        }
    }
    `;

    const response = await API.graphql({
        query: submitUserQuizQuery,
        variables: {
            input: data
        }
    });
    return response.data.submitUserQuiz;
};

// =====
// ASSIGNMENT
// =====

export const getAssignment = async (data) => {
    const getAssignmentQuery = `
        query GetAssignment($input: GetAssignmentInput) {
            getAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
    `;

    const response = await API.graphql({
        query: getAssignmentQuery,
        variables: {
            input: data
        }
    });
    return response.data.getAssignment;
};

export const createAssignment = async (data) => {
    const createAssignmentMutation = `
        mutation CreateAssignment($input: CreateAssignmentInput) {
            createAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
    `;

    const response = await API.graphql({
        query: createAssignmentMutation,
        variables: {
            input: data
        }
    });
    return response.data.createAssignment;
};

export const submitAssignmentResponse = async (data) => {
    const submitAssignmentResponseMutation = `
        mutation submitAssignmentResponse($input: SubmitAssignmentResponseInput) {
            submitAssignmentResponse(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
        }
    }
    `;

    const response = await API.graphql({
        query: submitAssignmentResponseMutation,
        variables: {
            input: data
        }
    });
    return response.data.submitAssignmentResponse;
};

export const completeAssignment = async (data) => {
    const completeAssignmentMutation = `
        mutation CompleteAssignment($input: CompleteAssignmentInput) {
            completeAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
    `;

    const response = await API.graphql({
        query: completeAssignmentMutation,
        variables: {
            input: data
        }
    });
    return response.data.completeAssignment;
};
