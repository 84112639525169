import React from 'react';
import MenuList from './MenuList';

const MobileMenu = ({ modules, menuItems, isMenuOpen, setIsMenuOpen, contentId }) => {
    return (
        <div className="flex">
            <div className="mobile-menu">
                <div className="h-screen w-[60vw] overflow-y-auto bg-white">
                    <MenuList
                        modules={modules}
                        menuItems={menuItems}
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        contentId={contentId}
                    />
                </div>
            </div>
            <div className="mobile-menu-overlay" onClick={() => setIsMenuOpen(false)}></div>
        </div>
    );
};

export default MobileMenu;
