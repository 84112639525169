import CompletedContent from '../components/home/Completed';
import InProgressContent from '../components/home/InProgress';
import MainContent from '../components/home/MainContent';

export const tabItems = [
    {
        key: '1',
        label: `HOME`,
        children: <MainContent />
    },
    {
        key: '2',
        label: `IN PROGRESS`,
        children: <InProgressContent />
    },
    {
        key: '3',
        label: `COMPLETED`,
        children: <CompletedContent />
    }
];
