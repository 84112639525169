import { Button, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';

const EmptyListIndicator = ({ message }) => {
    const navigate = useNavigate();
    const slug = localStorage.getItem('pathway-slug');
    const handleClick = () => {
        navigate(`/pathway/${slug}`);
    };
    return (
        <div className="flex h-[250px] w-full flex-col items-center justify-center border border-gray-500 bg-white">
            <Empty
                description={
                    <>
                        <h1>{message ? message : 'This list is empty.'}</h1>
                        <Button
                            className="mt-4 rounded-2xl bg-primary"
                            size="large"
                            type="primary"
                            onClick={() => handleClick()}>
                            Browse Courses
                        </Button>
                    </>
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
        </div>
    );
};

export default EmptyListIndicator;
