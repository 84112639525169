import { Button, Col, Form, Input, Typography } from 'antd';
import Logo from '../../../assets/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageLoader from '../../layout/loaders/PageLoader';
import { ToastContainer } from 'react-toastify';
import { showSuccessToast } from '../../../utils/ToastSuccess';
import { showErrorToast } from '../../../utils/ToastError';
import { API_GATEWAY_ENDPOINT } from '../../../utils/ApiGwOptions';

const Register = () => {
    const { Text, Title } = Typography;

    const [peopleMgrData, setPeopleMgrData] = useState(null);
    const [updatePasswordResponse, setUpdatePasswordResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setPeopleMgrData({
                name: searchParams?.get('name'),
                email: searchParams?.get('email'),
                id: searchParams?.get('id')
            });
        }, 1000);
    }, []);

    useEffect(() => {
        if (updatePasswordResponse?.status) {
            if (updatePasswordResponse?.status === 200) {
                showSuccessToast({ message: 'Password saved. Redirecting to sign-in.' });
                setTimeout(() => {
                    navigate('/signin?isManager=true');
                }, 2000);
            } else {
                showErrorToast({ message: 'Password update failed.' });
            }
        }
    }, [updatePasswordResponse]);

    const layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    };

    const validatePassword = (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Please input password'));
        }

        if (value.length < 8) {
            return Promise.reject(new Error('Password must be at least 8 characters'));
        }

        if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
            return Promise.reject(
                new Error(
                    'Password must contain at least one capital letter, one numeric value, and one special character'
                )
            );
        }

        return Promise.resolve();
    };

    const onFinish = async (values) => {
        const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/updatePeopleMgr?id=${peopleMgrData.id}&email=${peopleMgrData.email}&password=${values.password}`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let response;
        const submitNewPassword = async () => {
            setIsLoading(true);
            try {
                response = await fetch(REQUEST_URL, options);
                setUpdatePasswordResponse(response);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
            setIsLoading(false);
        };
        submitNewPassword();
    };

    return (
        <>
            {peopleMgrData === null ? (
                <PageLoader />
            ) : (
                <Col className="h-screen pt-48">
                    <Col className="mx-auto w-[450px]">
                        <Col className="mb-8 flex flex-col items-center justify-center">
                            <img src={Logo} alt="Logo" width={70} className="mb-8" />
                            <Text>Hello {peopleMgrData?.name}, welcome to Talentboost Platform's</Text>
                            <Title style={{ marginTop: '0px' }}>People Manager Console</Title>

                            <Text className="text-slate-40 ">
                                To complete account setup, provide your desired password below:
                            </Text>
                        </Col>
                        <Form {...layout} name="set-password-form" className="mx-auto px-2" onFinish={onFinish}>
                            <Form.Item name="password" rules={[{ validator: validatePassword }]}>
                                <Input.Password
                                    size="large"
                                    placeholder="Password"
                                    className="input-centered-round"
                                    id="new-password"
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                id="confirm-password"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Please confirm your password' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The passwords do not match'));
                                        }
                                    })
                                ]}>
                                <Input.Password
                                    size="large"
                                    placeholder="Confirm Password"
                                    className="input-centered-round"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    htmlType="submit"
                                    className="signin mt-4 "
                                    loading={isLoading}>
                                    Set Password
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Col>
            )}
            <ToastContainer />
        </>
    );
};

export default Register;
