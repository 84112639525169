import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProviderContext } from '../../providers/AuthProvider';

const ProtectedRoute = ({ children }) => {
    const { authState } = useContext(AuthProviderContext);
    const navigate = useNavigate();
    const managerToken = localStorage.getItem('PeopleMgrToken');

    useEffect(() => {
        if (!managerToken) {
            if (!authState.isAuthenticated) {
                navigate('/signin');
            }
        }
    }, [authState.isAuthenticated, navigate]);

    return authState.isAuthenticated ? children : null;
};

export default ProtectedRoute;
