import { Col, Dropdown, Space, Avatar } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';
import UserAvatar from '../../../assets/avatar.png';
import { useNavigate } from 'react-router-dom';
import { AuthProviderContext } from '../../../providers/AuthProvider';

const ConsoleHeader = () => {
    const MenuItems = [
        {
            key: '1',
            label: 'Sign Out'
        }
    ];

    const navigate = useNavigate();
    const { authState, authDispatch } = useContext(AuthProviderContext);

    const appSignOut = () => {
        localStorage.removeItem('PeopleMgrToken');
        authDispatch({ type: 'SIGN_OUT' });
        navigate('/signin');
    };
    return (
        <div className=" fixed z-50 flex h-[90px] w-[calc(100vw-250px)] items-center justify-between  bg-white  px-8 py-4 drop-shadow-xl">
            <h1 className=" p-2 text-2xl font-semibold leading-none">GCash Tech Academy - Fintech Talent Engine</h1>

            <Space direction="vertical" size={120}>
                <Dropdown menu={{ items: MenuItems, onClick: appSignOut }}>
                    <Space align="end" className="cursor-pointer">
                        <Avatar src={UserAvatar} className="h-[40px] w-auto" />
                    </Space>
                </Dropdown>
            </Space>
        </div>
    );
};

export default ConsoleHeader;
