import React, { useState, useContext } from 'react';
import { Card, Col, Row, Typography, Button } from 'antd';
import { API } from 'aws-amplify';

import PageLoader from '../components/layout/loaders/PageLoader';
import Logo from '../assets/logo.png';
import '../assets/css/assessment.css';

// Providers
import { AuthProviderContext } from '../providers/AuthProvider';
import { UserProviderContext } from '../providers/UserProvider';

import AboutCandidateForm from '../components/assessment/FormCandidate';
import SignOutButton from '../components/layout/ButtonSignOut';

const { Paragraph, Title } = Typography;

const createAssessment = async () => {
    const createAssessmentQuery = `
        mutation createAssessment {
            createAssessment {
                id
                userId
                quizId
                orgId
                status
                attempts {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                mostRecentAttempt {
                    answers {
                        questionCode
                        answer
                    }
                    timeStarted
                    results
                }
                createdAt
                updatedAt
            }
    }`;

    await API.graphql({ query: createAssessmentQuery });
};

const createUser = async (payload, dispatch, user) => {
    const { username, name, email, currentJob, wantedPath } = payload;

    const createUserMutation = `
        mutation createUser($input: CreateUserInput!) {
            createUser(input: $input) {
                id
                username
                orgId
                name
                email
                role
                currentJob
                wantedPath
                enrollmentStatus
                createdAt
                updatedAt
            }
        }
    `;

    let result = await API.graphql({
        query: createUserMutation,
        variables: {
            input: { username, name, email, currentJob, wantedPath }
        }
    });

    // API call to creation of UserAssementQuiz
    await createAssessment();

    dispatch({ type: 'SET_API_USER', payload: { ...result.data.createUser, enrollmentStatus: 'PENDING_ASSESSMENT' } });
};

const UsagePolicySection = ({ setContinue }) => {
    const disagree = () => setContinue(false);
    const { userState, userDispatch } = useContext(UserProviderContext);
    const { username, email } = userState.cognitoUser;
    const { name, currentJob, wantedPath } = userState.apiUser;

    const submit = () => {
        let params = { username, name, email, currentJob, wantedPath };
        userDispatch({ type: 'LOADING' });
        // API call to create user
        createUser(params, userDispatch, userState);
    };

    return (
        <Col xs={24} sm={24} md={20} lg={12} xl={12} style={{ padding: '20px' }}>
            <img src={Logo} alt="Logo" className="medium-logo" />
            <Card
                bordered={false}
                className="rounded-t-none border-t-8 border-solid border-primary"
                style={{ width: '100%' }}>
                <Title level={5}>You are about to take the Technical Diagnostic Exam</Title>
                <Title level={5}>To use this platform, you must agree to the following terms.</Title>
                <Paragraph>
                    By using this platform, you consent to the collection, use, and disclosure of your personal
                    information in accordance with our privacy policy. Your personal information may include your name,
                    contact information, employment history, and other information relevant to your job search and
                    talent development.
                </Paragraph>
                <Paragraph>
                    We may use your personal information to provide you with job opportunities, training resources, and
                    other related services. We may also disclose your personal information to third-party service
                    providers who assist us in delivering these services. These service providers are contractually
                    obligated to protect the confidentiality and security of your personal information.
                </Paragraph>
                <Paragraph>
                    We take reasonable measures to safeguard your personal information and protect it against
                    unauthorized access, use, or disclosure. However, please note that no method of transmission over
                    the internet or electronic storage is completely secure. By using this talent training platform, you
                    acknowledge that you have read and understood this data privacy consent, and that you consent to the
                    collection, use, and disclosure of your personal information as described above.
                </Paragraph>
                <Row justify="end" gutter={8}>
                    <Col span={8}>
                        <Button
                            // type="primary"
                            size="large"
                            shape="round"
                            className="disagree"
                            onClick={() => disagree()}>
                            Disagree
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            className="agree"
                            onClick={() => submit()}
                            loading={userState.loading}>
                            Agree
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

const AssessmentWelcomePage = () => {
    const { authState } = useContext(AuthProviderContext);
    const [cont, setContinue] = useState(false);

    return !authState.loading ? (
        <React.Fragment>
            <SignOutButton type="link" size="large" />
            <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
                {cont ? (
                    <UsagePolicySection setContinue={setContinue} />
                ) : (
                    <AboutCandidateForm setContinue={setContinue} />
                )}
            </Row>
        </React.Fragment>
    ) : (
        <PageLoader />
    );
};

export default AssessmentWelcomePage;
