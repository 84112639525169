import React from 'react';
import { Typography, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ currentLessonIndicator }) => {
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const navigate = useNavigate();

    const pathwayTitle = localStorage.getItem('pathway-title');
    const pathwaySlug = localStorage.getItem('pathway-slug');

    const limitStringWithEllipsis = (inputString) => {
        const limit = 36;
        if (inputString?.length > limit) {
            return inputString?.slice(0, limit) + '...';
        } else {
            return inputString;
        }
    };

    let displayLessonName;

    if (xs) {
        displayLessonName = limitStringWithEllipsis(currentLessonIndicator?.lessonTitle?.split(':')[0]);
    } else {
        displayLessonName = currentLessonIndicator?.lessonTitle?.split(':')[0];
    }

    return (
        <>
            {currentLessonIndicator.module ? (
                <div className={`flex ${xs ? 'flex-col' : ''}`}>
                    {!xs && (
                        <>
                            <Text
                                className="cursor-pointer text-gray-500"
                                onClick={() => {
                                    navigate(`/pathway/${pathwaySlug}?t=${pathwayTitle}`);
                                }}>
                                {pathwayTitle} /
                            </Text>
                        </>
                    )}
                    <Text className={`${!xs ? 'mx-1' : ''} text-gray-500`}>{currentLessonIndicator.module} /</Text>
                    <Text>{displayLessonName}</Text>
                </div>
            ) : (
                <p className="custom-loader" style={{ width: '350px', height: '18px', opacity: '0.2' }}></p>
            )}
        </>
    );
};

export default Breadcrumbs;
