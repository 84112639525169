import React, { useContext, useEffect, useState } from 'react';
import { Layout, Tabs, Grid } from 'antd';
import { useNavigate, Outlet } from 'react-router-dom';

// Pages
import AssessmentWelcomePage from './AssessmentWelcome';
import AssessmentQuizPage from './AssessmentQuiz';

// Providers
import { UserProviderContext } from '../providers/UserProvider';
import { AuthProviderContext } from '../providers/AuthProvider';

// Components
import MainHeader from '../components/layout/Header';
import Background from '../components/layout/Background';
import PageLoader from '../components/layout/loaders/PageLoader';

import { tabItems } from '../data/HomeTabItems';
const { Content } = Layout;

export const Dashboard = () => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    return (
        <Layout className="home-body">
            <Background />
            <MainHeader />
            <Content
                style={{
                    minHeight: '100vh',
                    position: 'relative'
                }}>
                <div className="home-tabs">
                    <Tabs
                        defaultActiveKey="1"
                        items={tabItems}
                        style={{
                            padding: xs ? '20px 36px' : '20px 60px',
                            minHeight: '100vh',
                            maxWidth: '1920px',
                            marginInline: 'auto'
                        }}></Tabs>
                </div>
            </Content>
        </Layout>
    );
};

const HomePage = () => {
    const { userState } = useContext(UserProviderContext);
    const { authState } = useContext(AuthProviderContext);

    const [user, setUser] = useState();
    const [localLoader, setLocalLoader] = useState(true);
    const navigate = useNavigate();

    const managerToken = localStorage.getItem('PeopleMgrToken');

    useEffect(() => {
        if (managerToken) {
            navigate('/manager-console');
        }

        if (!managerToken) {
            if (!authState.loading && !authState.isAuthenticated) {
                navigate('/signin');
            }
            if (userState.apiUser) {
                setUser(userState.apiUser);
                setLocalLoader(false);
            }
        }
    }, [authState, userState.apiUser, navigate]);

    if (user && user.enrollmentStatus === 'FOR_ASSESSMENT') {
        return <AssessmentWelcomePage />;
    }

    if (user && user.enrollmentStatus === 'PENDING_ASSESSMENT') {
        return <AssessmentQuizPage />;
    }

    if (user && !user.enrollmentStatus) {
        return <AssessmentQuizPage />;
    }

    return localLoader ? <PageLoader /> : <Outlet />;
};

export default HomePage;
