import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Layout, Typography, Col, Row, Space, Avatar, Grid, Dropdown } from 'antd';
import UserAvatar from '../../assets/avatar.png';
import WhiteLogo from '../../assets/logo-white.png';
import BlueLogo from '../../assets/logo.png';
import moment from 'moment';
import { UserProviderContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';

const MenuItems = [
    {
        key: '1',
        label: 'Sign Out'
    }
];

const CoursePageHeader = ({ textPrimary, hideWelcome, primaryLogo, primaryBg }) => {
    const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

    const { Header } = Layout;
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const appSignOut = () => {
        Auth.signOut().then(() => {
            window.location.reload();
        });
    };

    const { userState } = useContext(UserProviderContext);
    const { name } = userState?.cognitoUser;

    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm A'));
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="flex justify-center bg-primary">
            <Header
                className={`
                ${xs ? 'px-4' : 'px-12'}
                z-10 flex h-full w-full max-w-[1920px] items-center justify-between bg-primary py-4`}>
                <Row className="my-auto w-full flex-col">
                    <Row justify="space-between">
                        <Col className="flex">
                            <Space>
                                <img
                                    src={primaryLogo ? BlueLogo : WhiteLogo}
                                    alt="logo"
                                    className="h-[50px]"
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Text className={`font-bold ${textPrimary ? 'text-primary' : 'text-white'}`}>
                                    {currentTime}
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            <Space direction="vertical" size={120}>
                                <Dropdown menu={{ items: MenuItems, onClick: appSignOut }}>
                                    <Space align="center" className="cursor-pointer">
                                        <Text
                                            className={`
                                            ${textPrimary ? 'text-primary' : 'text-white'}
                                            ${xs ? 'hidden' : ''}
                                            font-bold`}>
                                            {name}
                                        </Text>
                                        <Avatar src={UserAvatar} className="h-[50px] w-auto" />
                                    </Space>
                                </Dropdown>
                            </Space>
                        </Col>
                    </Row>
                </Row>
            </Header>
        </div>
    );
};

export default CoursePageHeader;
