import React, { createContext, useReducer, useEffect } from 'react';
import PageLoader from '../components/layout/loaders/PageLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../utils/ApiGwOptions';

const initialState = {
    currentAuthenticatedUser: null,
    quizzesAndExams: [],
    labs: [],
    courses: [],
    saaResults: null,
    loading: true
};
export const PeopleManagerProviderContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_QUIZZES':
            return { ...state, quizzesAndExams: action.payload };
        case 'SAVE_LABS':
            return { ...state, labs: action.payload };
        case 'SAVE_COURSES':
            return { ...state, courses: action.payload };
        case 'SAVE_SAA_RESULTS':
            return { ...state, saaResults: action.payload };
        case 'SAVE_USER':
            return { ...state, currentAuthenticatedUser: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

export const PeopleManagerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            const getCurrentUser = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getPeopleMgr`;
                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (!data.peopleMgr) {
                    localStorage.removeItem('PeopleMgrToken');
                    navigate('/signin');
                }

                if (data) {
                    dispatch({ type: 'SAVE_USER', payload: { ...data.peopleMgr } });
                }
            };

            getCurrentUser();
        }
    }, []);

    return (
        <PeopleManagerProviderContext.Provider value={{ peopleMgrState: state, peopleMgrDispatch: dispatch }}>
            {location.pathname === '/manager-console/register' && children}
            {location.pathname !== '/manager-console/register' && state.currentAuthenticatedUser === null ? (
                <PageLoader />
            ) : (
                children
            )}
        </PeopleManagerProviderContext.Provider>
    );
};
