import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Select, Typography } from 'antd';
import { PeopleManagerProviderContext } from '../../../providers/PeopleManagerProvider';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions, token } from '../../../utils/ApiGwOptions';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { staging as stagingCourses } from '../../../data/PathwayCoursesStaging';
import { prod as prodCourses } from '../../../data/PathwayCoursesProd';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const GradesDetailed = () => {
    const location = useLocation();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [courseLabels, setCourseLabels] = useState([]);
    const { peopleMgrState, peopleMgrDispatch } = useContext(PeopleManagerProviderContext);
    const { quizzesAndExams } = peopleMgrState;
    const [displayData, setDisplayData] = useState([]);
    const [courseFilter, setCourseFilter] = useState(
        (process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses).map((item) => item.code)
    );

    const pathwayCoursesData = process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingCourses : prodCourses;

    useEffect(() => {
        if (token && quizzesAndExams.length === 0) {
            const getLearnersQuizzesAndExams = async () => {
                const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/getLearnersQuizzesAndExams`;

                let response = await fetch(REQUEST_URL, apiGatewayOptions);
                let data = await response.json();

                if (data) {
                    data.grades = data.grades?.sort((a, b) => {
                        const lastNameA = a.name.split(' ').pop();
                        const lastNameB = b.name.split(' ').pop();
                        return lastNameA.localeCompare(lastNameB);
                    });
                    peopleMgrDispatch({
                        type: 'SAVE_QUIZZES',
                        payload: data.grades
                    });

                    setDisplayData(data.grades);
                }
            };

            getLearnersQuizzesAndExams();
        }
    }, []);

    useEffect(() => {
        setDisplayData(quizzesAndExams);
    }, [location, quizzesAndExams]);

    useEffect(() => {
        // WHOLE USE EFFECT IS TO SET TABLE HEADERS, BOTH COURSE LABELS AND LESSON LABELS
        if (quizzesAndExams.length !== 0) {
            const parsedQuizzes = JSON.parse(quizzesAndExams[0]?.quizzes);
            const parsedExams = JSON.parse(quizzesAndExams[0]?.exams);

            const combined = {};

            // Include all courses from parsedQuizzes
            Object.keys(parsedQuizzes).forEach((course) => {
                const sortedQuizzes = (parsedQuizzes[course] || []).sort((a, b) => a.name.localeCompare(b.name));
                const sortedExams = (parsedExams[course] || []).sort((a, b) => a.name.localeCompare(b.name));

                combined[course] = [...sortedQuizzes, ...sortedExams];
            });

            // Include courses from parsedExams that are not already in combined
            Object.keys(parsedExams).forEach((course) => {
                const sortedExams = (parsedExams[course] || []).sort((a, b) => a.name.localeCompare(b.name));
                if (!combined[course]) {
                    combined[course] = sortedExams;
                }
            });

            const flattenedLessons = Object.values(combined).flatMap((items) => items);

            const tempObject = {};
            flattenedLessons.map((item) => {
                if (!tempObject[item.courseId]) {
                    tempObject[item.courseId] = 1;
                } else {
                    tempObject[item.courseId] = tempObject[item.courseId] + 1;
                }
            });
            const outputArray = Object.entries(tempObject).map(([courseId, count]) => ({
                courseId,
                count,
                courseCode: pathwayCoursesData.filter((item) => item.id === courseId)[0].code,
                courseTitle: pathwayCoursesData.filter((item) => item.id === courseId)[0].title
            }));

            outputArray.sort((a, b) => a.courseCode.localeCompare(b.courseCode));

            setCourseLabels(() => {
                return outputArray;
            });

            setTableHeaders(() => {
                let headers = [];
                outputArray.forEach((item) => {
                    combined[item.courseId]?.map((course) => {
                        headers.push(course);
                    });
                });

                return headers;
            });
        }
    }, [quizzesAndExams]);

    const handleNameFilter = (selectedNames) => {
        if (selectedNames.length !== 0) {
            setDisplayData(quizzesAndExams.filter((x) => selectedNames.includes(x.name)));
        } else {
            setDisplayData(quizzesAndExams);
        }
    };

    const handleCourseFilter = (selectedCourses) => {
        const allCourses = courseLabels.map((course) => course.courseCode);
        if (selectedCourses.length !== 0) {
            setCourseFilter(selectedCourses);
        } else {
            setCourseFilter(allCourses);
        }
    };

    const LearnerRow = (item, index) => {
        const parsedData = Object.values(JSON.parse(item.quizzes)).flatMap((items) => items);
        const parsedExams = Object.values(JSON.parse(item.exams)).flatMap((items) => items);

        parsedData.push(...parsedExams);

        const getPercentageScore = (q) => {
            if (q?.totalItems === 0) {
                return 0;
            }
            const score = (q?.score / q?.totalItems) * 100;
            return score;
        };
        return (
            <tr key={index}>
                <td>
                    {index + 1}. {item.name}
                </td>

                {tableHeaders
                    ?.filter((head) => headerCourseCodes.includes(head.courseId))
                    ?.map((head, index) => (
                        <td key={index} className="w-[200px]">
                            {parsedData.find((q) => q.name === head.name)
                                ? getPercentageScore(parsedData.find((q) => q.name === head.name)).toFixed(2)
                                : '0.00'}
                        </td>
                    ))}
            </tr>
        );
    };

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        } else {
            return str;
        }
    };

    const headerCourseCodes = courseFilter.map((course) => {
        const foundCourse = pathwayCoursesData.filter((pw) => pw.code === course)[0];
        return foundCourse?.id;
    });

    const isCourseDisabled = (code) => {
        const activeCourses = courseLabels.map((item) => item.courseCode);
        return !activeCourses.includes(code);
    };

    return (
        <div className="w-full">
            {displayData?.length === 0 ? (
                <CourseLoader isLesson={false} />
            ) : (
                <Col className="w-full">
                    <Title style={{ marginTop: '0px' }} className="">
                        Grades - Detailed
                    </Title>
                    <Row className=" mb-8 w-full">
                        <Select
                            allowClear
                            placeholder="Filter by Name"
                            onChange={handleNameFilter}
                            mode="multiple"
                            className="select-overflow multi-filter w-1/2 pr-4">
                            {quizzesAndExams.map((user, index) => {
                                return (
                                    <Option value={user.name} key={index}>
                                        {user.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Select
                            allowClear
                            placeholder="Filter by Course"
                            onChange={handleCourseFilter}
                            mode="multiple"
                            className="select-overflow w-1/2">
                            {pathwayCoursesData.map((course, index) => {
                                return (
                                    <Option value={course.code} key={index} disabled={isCourseDisabled(course.code)}>
                                        {course.code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Row>

                    <Row>
                        <div className="table-container  h-[calc(100vh-300px)] w-[calc(100vw-330px)] overflow-x-scroll">
                            <table className="grades-detailed">
                                <thead>
                                    <tr className="text-white">
                                        <th className="w-[240px] bg-gray-400" id="th-spacer"></th>

                                        {courseLabels
                                            ?.filter((course) => courseFilter.includes(course.courseCode))
                                            ?.map((item, index) => {
                                                return (
                                                    <th colSpan={item.count} className="" key={index}>
                                                        {truncateString(item.courseCode, 25)}
                                                    </th>
                                                );
                                            })}
                                    </tr>
                                    <tr className="  border-gray border-t border-solid text-white">
                                        <th id="th-name">Name</th>
                                        {tableHeaders
                                            ?.filter((head) => headerCourseCodes.includes(head.courseId))
                                            ?.map((item, index) => {
                                                return (
                                                    <th key={index} className="thead-names">
                                                        {truncateString(item.name, 15)}
                                                    </th>
                                                );
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayData?.map((item, index) => {
                                        return LearnerRow(item, index);
                                    })}
                                    {/* {displayData?.map((item, index) => {
                                        return LearnerRow(item, index);
                                    })}
                                    {displayData?.map((item, index) => {
                                        return LearnerRow(item, index);
                                    })}
                                    {displayData?.map((item, index) => {
                                        return LearnerRow(item, index);
                                    })} */}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Col>
            )}
        </div>
    );
};

export default GradesDetailed;
