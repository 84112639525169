import { Col, Row, Grid } from 'antd';

const PathwayLoader = () => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const loaderCount = [1, 2, 3, 4, 5];

    return (
        <Row gutter={[16, 16]}>
            {loaderCount.map((item, index) => {
                return (
                    <Col
                        key={index}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                        className={`${xs ? 'max-w-full' : 'max-w-[360px]'}`}>
                        <Row className="relative w-full rounded-none border-none">
                            <div className="custom-loader  flex w-full items-center justify-center pt-[100%]"></div>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );
};

export default PathwayLoader;
